import {
	Autocomplete,
	Chip,
	FormControlLabel,
	Grid,
	Link,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import './ShareReports.scss';
import Button from '../../components/Button/Button';
import useLocalStorage from '../../utils/localStorage';
import ShareReportsEndpoints from './ShareReportsEndpoints';
import useApiService from '../../services/api.service';
import { Controller, useForm } from 'react-hook-form';
import Input from '../../components/Input/Input';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { fontSize } from '@mui/system';

interface ShareThroughEmailProps {
	flag?: any;
	identifier?: any;
	isTableExport?: any;
	dateRange?: any;
	filters?: any;
	toaster?: any;
	loader?: any;
	name?: any;
	id?: any;
	shareReportsRequest?: any;
	exportPDFWithComponent?: any;
	closePopup?: any;
	getEmail?: any;
	downloadPDFClassName?: any;
	getTableExportNamesArray?: any;
	getTableExportEmailValue?: any;
}

const ShareThroughEmail: React.FC<ShareThroughEmailProps> = ({
	flag,
	identifier,
	isTableExport,
	dateRange,
	filters,
	toaster,
	loader,
	name,
	id,
	shareReportsRequest,
	exportPDFWithComponent,
	closePopup,
	getEmail,
	downloadPDFClassName,
	getTableExportEmailValue,
	getTableExportNamesArray,
}) => {
	const includeCSV = [
		'CMO',
		'Performance',
		'Channel Performance',
		'Paid Campaign Tracker',
		'Paid Media Tracker',
		'SEO',
		'Email Marketing',
		'Total Spend',
	];
	const APIService: any = useApiService();
	const userData: any = useLocalStorage.getItem('userData');
	const { control, handleSubmit, setValue }: any = useForm();
	const [values, setValues] = React.useState('CSV');
	const [showScheduling, setShowScheduling] = useState(false);
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let inputValue = (event.target as HTMLInputElement).value;

		// Remove commas immediately
		if (inputValue.includes(',')) {
			inputValue = inputValue.replace(/,/g, ''); // Remove commas
		}

		// Split by space and trim each email
		const emails = inputValue.split(/[\s,]+/).map((email) => email.trim());

		// Process each email
		const validEmails = emails.filter((email) => regName.test(email));

		// Find any invalid emails
		const invalidEmailExists = emails.some((email) => !regName.test(email));

		if (invalidEmailExists) {
			toaster.addToast({
				message: 'Please check the format of email',
				type: 'error',
				timeout: 2000,
			});
		}

		// Add valid emails as chips
		validEmails.forEach((email) => {
			if (!checkDuplicateChips(email)) {
				if (currentChipIndex > -1) {
					deletedAttributes.current.push(chipsList[currentChipIndex].name);
					chipsList[currentChipIndex].name = email;
					setCurrentChipIndex(-1);
				} else {
					addedAttributes.current.push(email);
					chipsList.push({ name: email, isDefault: 0, isEnabled: 1 });
				}
			}
		});

		// Reset the input field if any invalid email
		if (invalidEmailExists) {
			setChipValues('');
			setTextAreaValCopy('');
		} else {
			setChipValues("");
			setTextAreaValCopy("");
		}

		setChipsList([...chipsList]);
	};

	const [chipValues, setChipValues]: any = useState();
	const deletedAttributes: any = useRef([]);
	const addedAttributes: any = useRef([]);

	const [currentChipIndex, setCurrentChipIndex]: any = useState(-1);
	const [chipsList, setChipsList]: any = useState([]);

	const [textAreaValCopy, setTextAreaValCopy] = useState<string>('');

	let [emails, setEmails] = useState([]);
	const [scheduleRes, setScheduleRes] = useState();
	const [sendEmail, setSendEmail] = useState(false);
	const [namesArray, setNamesArray] = useState([]);
	const [tableFlag, setTableFlag] = useState(0);
	const url: any = window?.location?.href;
	const pmtcheck: any = url?.includes('paid-media-tracker');

	useEffect(() => {
		if (sendEmail && isTableExport) {
			getTableExportNamesArray(namesArray);
			getTableExportEmailValue(sendEmail);
		}
	}, [sendEmail, namesArray, tableFlag, getEmail]);
	const useStyles = makeStyles((theme: Theme) => ({
		inputLabel: {
			color: `${theme.palette.primary.main} !important`,
		},
		focused: {
			color: `${theme.palette.primary.main} !important`,
		},
	}));
	const classes = useStyles();

	const handleTableExportCSV = (chipsList: any, chipValues: any) => {
		if (chipValues) {
			const allEmails = chipValues.split(',').map((email: any) => email.trim());
			let invalidEmail = allEmails.some(
				(email: any) => regName.test(email) === false
			);
			if (invalidEmail) {
				toaster.addToast({
					message: 'Please check the format of email',
					type: 'error',
					timeout: 2000,
				});
				setSendEmail(false);
			} else {
				let combinedNames: any = [
					...chipsList.map((chip: any) => chip.name),
					...allEmails,
				];
				setNamesArray(combinedNames);
				setSendEmail(true);
			}
		} else {
			let combinedNames = chipsList.map((chip: any) => chip.name);
			setNamesArray(combinedNames);
			setSendEmail(true);
		}
	};

	const getEmails = (chips: any) => {
		let temp: any = [];
		chips?.map((chip: any) => {
			temp.push(chip.name);
		});
		// setSendEmail(true);
		return temp;
	};

	const [showChips, setShowChips]: any = useState(true);
	const submitEmail = async () => {
		const allEmails = chipValues
			? chipValues.split(',').map((email: any) => email.trim())
			: [];
		if (allEmails.length === 0 && chipsList.length === 0) {
			toaster.addToast({
				type: 'error',
				message: 'Please enter email to continue',
				timeout: 3000,
			});
		} else {
			if (values === 'CSV') {
				if (isTableExport) {
					handleTableExportCSV(chipsList, chipValues);
				}
				const headers = { 'access-token': userData['access-token'] };
				const request = {
					linking_type: 0,
					format_key: values === 'CSV' ? 0 : 1,
					email: chipsList.length
						? [...getEmails(chipsList), ...allEmails]
						: allEmails,
					dashboard_id: id,
					report_name: name,
					payload: { ...shareReportsRequest },
				};

				const reqBody = { headers, request };
				loader.showLoader();
				try {
					await APIService.post(ShareReportsEndpoints.shareReports(reqBody));
					toaster.addToast({
						type: 'success',
						message: 'Check your email',
						timeout: 3000,
					});
				} catch (err: any) {
					toaster.addToast({
						type: 'error',
						message: err.message,
						timeout: 3000,
					});
				} finally {
					loader.hideLoader();
				}
			}
		}
	};

	const handleChipDelete = (index: any, evt: any) => {
		chipsList.splice(index, 1);
		const findDelete = deletedAttributes.current?.findIndex(
			(deleteVal: any, deleteI: any) => deleteVal === evt
		);
		if (findDelete === -1) {
			deletedAttributes.current.push(evt);
		}
		setChipsList([...chipsList]);
		setCurrentChipIndex(-1);
	};

	const checkDuplicateChips = (evt: any) => {
		if (evt?.trim()) {
			return chipsList.find((val: any) => val.name === evt);
		}
		return false;
	};

	const regName =
		/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

	return (
		<Grid xs={12} container className='shareByEmail'>
			{/* -------radio buttons-------- */}
			<Grid
				xs={12}
				container
				display={'flex'}
				alignItems={'center'}
				justifyContent={'space-between'}
			>
				{!isTableExport && (
					<Grid xs={12} className='shareByEmail__radio'>
						<RadioGroup
							aria-labelledby='demo-controlled-radio-buttons-group'
							name='controlled-radio-buttons-group'
							value={values}
							style={{ display: 'flex', flexDirection: 'row', width: '30%' }}
							onChange={handleChange}
						>
							{includeCSV.includes(name) && (
								<Grid xs={6} style={{ display: pmtcheck ? 'none' : '' }}>
									{' '}
									<FormControlLabel
										value='CSV'
										control={
											<Radio
												size='small'
												sx={{
													'& .MuiSvgIcon-root': {
														fontSize: 28,
													},
												}}
											/>
										}
										label='CSV'
										labelPlacement='start'
									/>
								</Grid>
							)}
							<Grid xs={6}>
								<FormControlLabel
									className='className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"'
									value='PDF'
									control={
										<Radio
											size='small'
											sx={{
												'& .MuiSvgIcon-root': {
													fontSize: 28,
												},
											}}
										/>
									}
									label='PDF'
									labelPlacement='start'
								/>
							</Grid>
						</RadioGroup>
					</Grid>
				)}
			</Grid>

			<Grid item xs={12} className='chip_Container' mb={1}>
				{chipsList.length ? (
					<div>
						{chipsList.map((i: any, index: number) => (
							<Chip
								color='primary'
								style={{
									display: index > 1 && showChips == false ? 'none' : '',
								}}
								label={i.name}
								onClick={
									i.isDefault
										? undefined
										: (ev: any) => {
												setCurrentChipIndex(index);
												setChipValues(ev.target.textContent);
												setTextAreaValCopy(ev.target.textContent);
										  }
								}
								variant={index === currentChipIndex ? 'outlined' : 'filled'}
								className='textarea_chips'
								onDelete={
									i.isDefault
										? undefined
										: (evt: any) => handleChipDelete(index, i.name)
								}
							/>
						))}
						{showChips == false && chipsList.length > 2 ? (
							<Link
								onClick={() => {
									setShowChips(true);
								}}
							>
								See more
							</Link>
						) : showChips == true && chipsList.length > 2 ? (
							<Link
								onClick={() => {
									setShowChips(false);
								}}
							>
								See less
							</Link>
						) : null}
					</div>
				) : (
					<Grid xs={6} position={'absolute'}>
						<Typography>Enter one or more email</Typography>
					</Grid>
				)}
			</Grid>

			<Grid container position={'relative'} marginTop={'1rem'}>
				<Link
					style={{
						cursor: 'pointer',
						position: 'absolute',
						right: '1rem',
						top: '-2rem',
					}}
					onClick={() => {
						setChipValues('');
						setChipsList([]);
						setValue('email', null);
						setEmails([]);
					}}
				>
					Clear all
				</Link>
				<Grid xs={12} style={{ borderRadius: '40px' }} key={Math.random()}>
					<Input
						id='email'
						name='email'
						autoFocus={textAreaValCopy.length}
						label={chipsList.length > 0 ? 'Enter one or more email' : ''}
						placeholder='Press Enter to add multiple emails'
						value={chipValues}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setChipValues(event.target.value);
							setTextAreaValCopy(event.target.value);
						}}
						onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
							if (event.key === ',' || event.key === 'Enter') {
								handleChange(event as any);
								event.preventDefault();
							}
						}}
						defaultValue=''
						InputLabelProps={{
							classes: {
								root: classes.inputLabel,
								focused: classes.focused,
							},
						}}
					/>
					<em style={{fontSize: "xx-small", }}>Press Enter after each email</em>
					{/* <Input
						name='email'
						placeholder='Enter one or more email'
						control={control}
					/> */}
				</Grid>
			</Grid>
			{/* -------------------------schedule report------------------- */}

			<Grid
				xs={12}
				textAlign={'center'}
				style={{ marginTop: isTableExport ? '30px' : '0px' }}
			>
				<Button
					type='submit'
					onClick={() => {
						submitEmail();
					}}
					btnText='Send Report'
					style={{ width: '30%' }}
				/>
				<Button
					variant='outlined'
					type='button'
					btnText='Cancel'
					style={{ width: '30%' }}
					onClick={() => {
						closePopup();
					}}
				/>
			</Grid>
		</Grid>
	);
};

export default ShareThroughEmail;
