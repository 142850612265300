import React, { useEffect, useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Typography,
	Grid,
	useTheme,
	IconButton,
} from '@mui/material';
import DataTable from '../../../../components/DataTable/DataTable';
import CloseIcon from '@mui/icons-material/Close';
import { useFilterContext } from './FIltersContext';
import CustomSkeleton from '../../../../components/SkeletonLoading/SkeletonLoading';

const CustomDialog = (props: any) => {
	const theme = useTheme();
	const { selectedValues, setSelectedValues, limit, setLimit, offset, setOffset } = useFilterContext();
	const [open, setOpen] = useState(true);


	const handleClose = () => {
		setOpen(false);     
		props.onClose();     
	};


	return (
		<Dialog fullWidth maxWidth='md' open={props?.onOpen} onClose={handleClose}>
			<DialogTitle>
				{props.data.name}
				<IconButton
					aria-label='close'
					onClick={handleClose}
					sx={{
						position: 'absolute',
						right: theme.spacing(1),
						top: theme.spacing(1),
					}}
				>
					<CloseIcon color='primary' />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Typography>{props.data.InnerDescription}</Typography>
				{props?.data?.Loading ? (
						<CustomSkeleton
							variant={'rectangular'}
							height={500}
							width={'100%'}
							style={{ borderRadius: '20px' }}
						/>
					) : null}
					<Grid
						xs={12}
						style={{
							backgroundColor: theme.palette.mode == 'dark' ? 'black' : 'white',
							borderRadius: '20px',
							display: props?.data?.Loading ? 'none' : 'block',
						}}
					>
						<DataTable
							defineMargin={'1rem'}
							data={props.data.tabledata}
							columns={props.data.TableColumns}
							showPagination
							serverPagination
							handlePageChange={(event: any) => {
								setOffset(event)
							}}
							handlePerRowsChange={(event: any) => {
								setLimit(event)
							}}
							totalRows={props.data.TotalRows}
						/>
					</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default CustomDialog;
