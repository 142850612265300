import axios from 'axios';
import { LogoutSession } from './logutSessionFromKeyCloak';

const instance = axios.create({
	validateStatus: function (status) {
		return status >= 200;
	},
});
const cancelTokenSource = axios.CancelToken.source();

// Add a response interceptor
instance.interceptors.request.use(function (response) {
	response.validateStatus = function (status) {
		return status >= 200;
	};
	return response;
});

instance.interceptors.response.use(
	async (response) => {
		if (
			response.status !== 200 &&
			response.status !== 202 &&
			response.status !== 201
		) {
			if (response.status === 440) {
				LogoutSession.logoutSessionFromKeyCloak();
				cancelTokenSource.cancel(
					'Request was cancelled due to status code 440'
				);
				window.location.href = '/login';
				localStorage.clear();
			} else {
				return Promise.reject(response.data);
			}
		}
		return response.data;
	},
	(error) => {
		return Promise.reject(error);
	}
);

export default instance;
