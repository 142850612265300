import * as actionTypes from './actionTypes';

export const onLoginResponse = (data: any) => ({
	type: actionTypes.LOGIN_RESPONSE,
	data: { ...data },
});
export const onLogout = () => ({
	type: actionTypes.LOGOUT,
});
export const onLoader = (data: any) => ({
	type: actionTypes.LOADER,
	data: { ...data },
});

export const onSidebar = (data: any) => ({
	type: actionTypes.SIDEBAR,
	data: { ...data },
});

export const onInsightFilterChange = (data: any) => ({
	type: actionTypes.INSIGHT_FILTER_CHANGE,
	data: data,
});
export const onEditProfile = (data: any) =>
	//
	({
		type: actionTypes.EDITPROFILE,
		data: data,
	});
export const toggleSidebar = (data: any) =>
	//
	({
		type: actionTypes.TOGGLESIDEBAR,
		data: data,
	});

export const onSegmentListUpdate = (data: any) => ({
	type: actionTypes.SEGMENT_LIST_UPDATE,
	data: data,
});

export const onConnectorChanged = (data: any) => ({
	type: actionTypes.CONNECTOR_CHANGE,
	data: data,
});

export const onAccessAndRefreshTokenChange = (data: any) => ({
	type: actionTypes.ACCESS_AND_REFRESH_TOKEN_CHANGE,
	data: data,
});

export const onToggleTheme = (data: any) => {
	return {
		type: actionTypes.TOGGLETHEME,
		data: data,
	};
};
export const questionsDrawerHandler = (data: any) => ({
	type: actionTypes.INSIGHTS_DRAWER,
	data: data,
});

export const answerDrawerHandler = (data: any) => ({
	type: actionTypes.ANSWER_DRAWER,
	data: data,
});

export const setInsightsQuestionsHandler = (data: any) => ({
	type: actionTypes.INSIGHTS_QUESTIONS,
	data: data,
});
