const AiChatBotEndpoint = {
	askDiggChatBot(data: any) {
		return {
			url: 'diggichat/ask',
			data: data?.requestBody,
		};
	},
	generateComment(data: any) {
		return {
			url: 'diggichat/comment',
			data: data?.requestBody,
		};
	},
	getQuestionSuggestionList(data: any) {
		return {
			url: 'autofill/autofilling',
			// API_BASE_URL: 'https://beta.diggrowth.ai/',
			data: data?.requestBody,
		};
	},
	questionFeedBack(data: any) {
		return {
			url: 'diggichat/feedback',
			data: data,
		};
	},
	getQuestionHistory(data: any) {
		return {
			url: 'diggichat/history',
			data: data,
		};
	},
};

export default AiChatBotEndpoint;
