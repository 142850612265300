import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
	FormControl,
	FormHelperText,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from '@mui/material';
import { makeStyles, useTheme } from '@mui/styles';
import React, { useEffect } from 'react';

import './Dropdown.modules.scss';

interface IDropdownProps {
	name: any;
	itemList: any;
	label: any;
	valueSelector: string;
	selectedValue?: any;
	onChange?: any;
	ref?: any;
	labelName?: any;
	labelId?: any;
	disabled?: any;
	autoWidth?: any;
	variant?: any;
	id?: any;
	className?: string;
	onBlur?: any;
	inputShrink?: any;
	required?: any;
	placeholder?: string;
	defaultValue?: any;
	disableOption?: boolean;
	renderValue?: any;
	error?: any;
	errorMessage?: any;
	type?: any;
}

const useStyles = makeStyles((theme: any) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	inputLabelRoot: {
		color:
			theme.palette.mode == 'dark'
				? 'rgba(255, 255, 255,0.3)'
				: 'rgba(0, 0, 0, 0.38)',
	},
}));
const Dropdown: React.FC<IDropdownProps> = (props) => {
	const theme: any = useTheme();
	const onChangeDropdown = (event: any) => {
		if (!props.onChange) {
			return false;
		}
		props.onChange(event);
	};

	const renderTeamList = (itemList: any) =>
		itemList?.length > 0 &&
		itemList?.map((item: any, index: any) => (
			<MenuItem
				key={item[props.label] + index || item + index}
				className='dropdown__menu'
				value={
					item[props.valueSelector] !== undefined
						? item[props.valueSelector]
						: item
				}
				id={item[props.label] !== undefined ? item[props.label] : item}
				disabled={item.disableOption}
			>
				<Typography
					variant='h6'
					component='div'
					className='dropdown__textExceed'
				>
					{item[props.label] || item}
				</Typography>
			</MenuItem>
		));

	const classes = useStyles();

	return (
		<>
			<div
				// className='dropdown'
				className={theme.palette.mode === 'dark' ? 'dropdownDark' : 'dropdown'}
			>
				<FormControl
					variant='outlined'
					fullWidth
					error={props.error}
					size='small'
				>
					<InputLabel
						classes={props.type ? { root: classes.inputLabelRoot } : {}}
						variant='outlined'
						id={props.label}
					>
						{props.labelName || ''}
					</InputLabel>
					<Select
						label={props.labelName || ''}
						ref={props.ref}
						className={props.className}
						IconComponent={KeyboardArrowDownIcon}
						labelId={props.labelId || 'demo-controlled-open-select-label'}
						fullWidth
						defaultValue={props.defaultValue}
						required={props.required ? props.required : false}
						name={props.name ? props.name : 'demo-simple-select'}
						id={props.id ? props.id : 'demo-simple-select'}
						variant={props.variant ? props.variant : 'outlined'}
						value={props.selectedValue}
						onChange={onChangeDropdown}
						onBlur={props.onBlur ? props.onBlur : null}
						disabled={props.disabled ? props.disabled : false}
						autoWidth={props.autoWidth ? props.autoWidth : false}
						placeholder={props.placeholder}
						renderValue={props.renderValue ? props.renderValue : null}
						MenuProps={{
							classes: {
								paper: 'dropdown__menu__paper',
							},
						}}
						// textFieldProps={{
						// 	label: "Label",
						// 	InputLabelProps: {shrink:true}//Modified line
						// }}
					>
						{renderTeamList(props.itemList)}
					</Select>
				</FormControl>
				{props.error ? (
					<FormHelperText
						style={{ marginLeft: '0.5rem', marginTop: '0rem', color: 'red' }}
					>
						{props.errorMessage ? props.errorMessage : 'Required'}
					</FormHelperText>
				) : null}
			</div>
		</>
	);
};

export default Dropdown;
