import { createContext, useContext, useState, useEffect } from 'react';

interface FilterContextType {
    selectedValues: string[][];
    setSelectedValues: React.Dispatch<React.SetStateAction<string[][]>>;
    limit: number;
    setLimit: React.Dispatch<React.SetStateAction<number>>;
    offset: number;
    setOffset: React.Dispatch<React.SetStateAction<number>>;
}

const FilterContext = createContext<FilterContextType | undefined>(undefined);

export const useFilterContext = () => {
    const context = useContext(FilterContext);
    if (!context) {
        throw new Error('useFilterContext must be used within a FilterProvider');
    }
    return context;
};

export const FilterProvider: React.FC = ({ children }) => {
    const [selectedValues, setSelectedValues] = useState<string[][]>(() => {
        // Initialize from local storage if available, otherwise use default value
        const storedValues = localStorage.getItem('selectedValues');
        return storedValues ? JSON.parse(storedValues) : [[], [], []];
    });

    const [limit, setLimit] = useState<number>(() => {
        // Initialize from local storage if available, otherwise use default value
        const storedLimit = localStorage.getItem('limit');
        return storedLimit ? parseInt(storedLimit, 10) : 10; // default limit of 10
    });

    const [offset, setOffset] = useState<number>(() => {
        // Initialize from local storage if available, otherwise use default value
        const storedOffset = localStorage.getItem('offset');
        return storedOffset ? parseInt(storedOffset, 10) : 1; // default offset of 0
    });

    // Save to local storage whenever selectedValues, limit, or offset changes
    useEffect(() => {
        localStorage.setItem('selectedValues', JSON.stringify(selectedValues));
    }, [selectedValues]);

    useEffect(() => {
        localStorage.setItem('limit', limit.toString());
    }, [limit]);

    useEffect(() => {
        localStorage.setItem('offset', offset.toString());
    }, [offset]);

    return (
        <FilterContext.Provider value={{ selectedValues, setSelectedValues, limit, setLimit, offset, setOffset }}>
            {children}
        </FilterContext.Provider>
    );
};
