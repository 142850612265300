import { useAppContext } from "../AppContext/App.context";
import * as actions from "../AppContext/actions/actions";

const useLoader = () => {
  const [, dispatch]: any = useAppContext();

  return {
    showLoader: () => {
      dispatch(actions.onLoader({ showLoader: true }));
    },
    hideLoader: () => {
      dispatch(actions.onLoader({ showLoader: false }));
    },
    sidebarToggle: (type:any) => {
      dispatch(actions.onSidebar({ sidebarToggle:  type}));
    },
  };
};

export default useLoader;
