import { Grid, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import DataTable from '../../../../components/DataTable/DataTable';
import tableIcon from '../../../../assets/Images/table.svg';
import BarGraph from '../../../../assets/Images/barGraph.svg';
import { useEffect, useState } from 'react';
import BarGraphLeadsByChannel from './LeadsByChannelGraph';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import { TableColumnsLeadsByChannel } from './LeadScoringData';
import CustomSkeleton from '../../../../components/SkeletonLoading/SkeletonLoading';

const LeadsByChannel = (props: any, ref: any) => {
	const theme: any = useTheme();
	const [viewType, setViewType] = useState<any>('table');
	const [selectView, setSelectView] = useState(() => {
		// Fetch the value from localStorage on component mount
		return localStorage.getItem('selectedView') || 'Categorized View';
	});
	const [barGraphData, setBarGraphData] = useState<any>();
	const [updatedSeries, setUpdatedSeries] = useState<any>();

	const barGraph = {
		categories: barGraphData?.channels,
		series: updatedSeries || [],
	};
	function extractData(data: any) {
		let channels = [];
		let A = [];
		let B = [];
		let C = [];
		let overall = [];
		for (let i = 0; i < data?.length; i++) {
			let entry = data[i];
			channels.push(entry.channel);
			A.push(entry.COUNT_A);
			B.push(entry.COUNT_B);
			C.push(entry.COUNT_C);
			overall.push(entry.COUNT_TOTAL);
		}

		// Set the extracted data to state
		setBarGraphData({
			channels: channels,
			A: A,
			B: B,
			C: C,
			overall: overall,
		});

		// Prepare updated series based on selectView
		if (selectView !== 'Overall') {
			setUpdatedSeries([
				{ data: A, name: 'Grade A', color: '#9747FF' },
				{ data: B, name: 'Grade B', color: '#E600FF' },
				{ data: C, name: 'Grade C', color: '#00E9FA' },
			]);
		} else {
			setUpdatedSeries([{ data: overall, name: 'Overall', color: '#9747FF' }]);
		}
	}

	useEffect(() => {
		// Save the selected view to localStorage whenever it changes
		localStorage.setItem('selectedView', selectView);
		extractData(props?.Data?.table_data);
	}, [props, selectView]);

	return (
		<>
			<div className='reportHeading'>
				<Typography variant='h4' component='span'>
					{props.title}
				</Typography>
			</div>
			<Grid
				container
				className='channel_estimates_container reduce-height'
				style={{
					paddingBottom: '0rem',
					boxShadow: 'none',
					backgroundColor: theme.palette.mode == 'dark' ? '#1A1A1A' : '#F4F7FC',
				}}
			>
				<Grid
					xs={12}
					style={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<Grid
						style={{
							margin: '20px 0px 0px 20px',
							display: 'flex',
							gap: '10px',
						}}
					>
						<IconButton
							style={{
								backgroundColor:
									theme.palette.mode == 'dark' ? 'black' : 'white',
								border: viewType === 'table' ? '3px solid #E600FF' : 'none',
								padding: '9px',
								borderRadius: '25px',
								lineHeight: '3px',
							}}
							onClick={() => setViewType('table')}
						>
							<img className='whiteColor' src={tableIcon} alt='' />
						</IconButton>
						<IconButton
							style={{
								backgroundColor:
									theme.palette.mode == 'dark' ? 'black' : 'white',
								border: viewType === 'graph' ? '3px solid #E600FF' : 'none',
								padding: '9px',
								borderRadius: '25px',
								lineHeight: '3px',
							}}
							onClick={() => setViewType('graph')}
						>
							<img className='whiteColor' src={BarGraph} alt='' />
						</IconButton>
					</Grid>
					{viewType == 'graph' ? (
						<Grid style={{ width: '200px', margin: '20px 20px 0px 0px' }}>
							<Dropdown
								valueSelector='value'
								itemList={['Categorized View', 'Overall']}
								label=''
								name='Date Type'
								onChange={(e: any) => {
									setSelectView(e.target.value);
								}}
								labelName='Filter By'
								defaultValue={selectView} // Use selectView as defaultValue
							/>
						</Grid>
					) : (
						<></>
					)}
				</Grid>
				{props?.isLoading ? (
					<CustomSkeleton
						variant={'rectangular'}
						height={500}
						width={'100%'}
						style={{ borderRadius: '20px', margin: '1rem' }}
					/>
				) : (
					<Grid
						container
						style={{
							padding: '1rem',
							boxShadow: 'none',
							backgroundColor:
								theme.palette.mode == 'dark' ? '#1A1A1A' : '#F4F7FC',
							borderRadius: '20px',
						}}
					>
						{viewType == 'graph' ? (
							<>
								{props?.isLoading ? (
									<CustomSkeleton
										variant={'rectangular'}
										height={500}
										width={'100%'}
										style={{ borderRadius: '20px' }}
									/>
								) : (
									<Grid
										xs={12}
										style={{ backgroundColor: 'black', borderRadius: '20px' }}
									>
										<BarGraphLeadsByChannel
											type='column'
											yAxisName={'Leads'}
											series={barGraph.series}
											categories={barGraph.categories}
										/>
									</Grid>
								)}
							</>
						) : props?.isLoading ? (
							<CustomSkeleton
								variant={'rectangular'}
								height={500}
								width={'100%'}
								style={{ borderRadius: '20px' }}
							/>
						) : (
							<Grid
								xs={12}
								style={{
									backgroundColor:
										theme.palette.mode == 'dark' ? 'black' : 'white',
									borderRadius: '20px',
								}}
							>
								<DataTable
									defineMargin={'1rem'}
									data={props?.Data?.table_data}
									columns={TableColumnsLeadsByChannel}
									topLeftandRightBorderRadius='10px'
								/>
							</Grid>
						)}
					</Grid>
				)}
			</Grid>
		</>
	);
};

export default LeadsByChannel;
