import React, { createContext, useContext, useEffect, useState } from 'react';

const TabContext = createContext();

export const useTabType = () => useContext(TabContext);

export const TabProvider = ({ children }) => {
  // Retrieve initial state from local storage or default to 0
  const initialLevel = Number(localStorage.getItem('level')) || 0;
  const initialMMMLevel = Number(localStorage.getItem('MMMLevel')) || 0;

  const [level, setLevel] = useState(initialLevel);
  const [MMMLevel, setMMMLevel] = useState(initialMMMLevel);

  useEffect(() => {
    // Save level to local storage when it changes
    localStorage.setItem('level', level);
  }, [level]);

  useEffect(() => {
    // Save MMMLevel to local storage when it changes
    localStorage.setItem('MMMLevel', MMMLevel);
  }, [MMMLevel]);

  return (
    <TabContext.Provider value={{ level, setLevel, MMMLevel, setMMMLevel }}>
      {children}
    </TabContext.Provider>
  );
};

export default TabContext;
