import ApiManager from '../../../services/apiManager';
import AiChatBotEndpoint from './AiChatBotApiEndpoint';
import headerService from '../../../services/header.service';

export const getRecommendationList = async (requestData) => {
	try {
		await ApiManager.post(
			AiChatBotEndpoint.getQuestionSuggestionList(requestData),
			true,
			false,
			true
		)
			.then((response) => {
				const suggestionData = {
					type: 'setSuggestionList',
					data: response.data.suggestions,
				};
				const suggestionList = {
					type: 'isSuggestionListPresent',
					data: true,
				};
				headerService.emit(suggestionList);
				headerService.emit(suggestionData);
			})
			.catch((error) => {
				console.log(error, '<<< Recommendation Api Failure >>>');
			});
	} catch (error) {
		console.log('<<< Recommendation List Failure >>>' + error);
	}
};

export const getQuestionAnswer = async (requestBody, question) => {
	try {
		try {
			await ApiManager.post(AiChatBotEndpoint.askDiggChatBot(requestBody), true)
				.then((response) => {
					const apiResponse = {
						type: 'diggiChatresponseFromApi',
						data: { ...response.data, question },
					};
					headerService.emit(apiResponse);
					const focusArea = {
						type: 'isSuggestionListPresent',
						data: false,
					};
					const addQuestionToSideBar = {
						type: 'addNewRecentQuestion',
						data: {
							question: question?.question || question,
							data_source: requestBody.requestBody.datasource,
						},
					};
					headerService.emit(addQuestionToSideBar);

					headerService.emit(focusArea);
					const disableTextField = {
						type: 'disableNewQuestion',
						data: false,
					};

					headerService.emit(disableTextField);
				})
				.catch((error) => {
					const apiResponse = {
						type: 'diggiChatresponseFromApi',
						data: { ...error.data, question: question },
					};
					headerService.emit(apiResponse);
					const focusArea = {
						type: 'isSuggestionListPresent',
						data: false,
					};

					headerService.emit(focusArea);
					const disableTextField = {
						type: 'disableNewQuestion',
						data: false,
					};

					headerService.emit(disableTextField);
					console.log('<<< Errow While Api Hit (diggichat/ask) >>>' + error);
				});
		} catch (error) {}
	} catch (error) {
		console.log('<<< Error While Question Asked >>>' + error);
	}
};

export const getQuestionHistory = async () => {
	try {
		try {
			let request = {
				limit: 1,
				offset: 10,
			};
			await ApiManager.post(AiChatBotEndpoint.getQuestionHistory(request), true)
				.then((response) => {
					const questionHistory = {
						type: 'historyQuestions',
						data: response.data,
					};
					const trendingQuestions = {
						type: 'trendingQuestions',
						data: response.data.df_faq,
					};
					headerService.emit(trendingQuestions);
					headerService.emit(questionHistory);
				})
				.catch(() => {});
		} catch (error) {
			console.log('<<< Errow While Api Hit (diggichat/history) >>>' + error);
		}
	} catch (error) {
		console.log('<<< Error While Question History >>>' + error);
	}
};
