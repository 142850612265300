import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme, useMediaQuery } from '@mui/material';

function CircularProgressWithLabel(props: any) {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down(1200));
	const isMediumScreen = useMediaQuery(theme.breakpoints.between(1200, 1400));

	// Set circular progress size based on screen size
	const circularProgressSize = isSmallScreen ? 80 : isMediumScreen ? 130 : 180;
	const fontSize = isSmallScreen ? 10 : isMediumScreen ? 9 : 12;
	return (
		<Box
			sx={{
				position: 'relative',
				display: 'inline-flex',
				width: `${circularProgressSize}px`,
				height: `${circularProgressSize}px`,
				margin: '10px',
			}}
		>
			<CircularProgress
				variant='determinate'
				style={{
					strokeLinecap: 'round',
					color: '#E600FF',
					strokeWidth: '5',
					position: 'absolute',
					zIndex: 1,
				}}
				{...props}
				size={circularProgressSize}
			/>
			<CircularProgress
				variant='determinate'
				style={{
					strokeLinecap: 'round',
					color: theme.palette.mode === 'dark' ? '#1A1A1A' : '#F4F7FC',
					strokeWidth: '5',
					position: 'absolute',
					zIndex: 0,
				}}
				value={100}
				size={circularProgressSize}
			/>
			<Box
				sx={{
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					flexDirection: 'column',
					width: '100%',
					height: '100%',
					zIndex: 2,
				}}
			>
				<Typography
					variant='h5'
					component='div'
					color='text.primary'
					style={{ marginBottom: '20px' }}
				>
					{props.value !== '-' ? `${props.value}%` : props.value}
				</Typography>
				<Typography
					component='span'
					color='text.primary'
					fontSize={fontSize}
				>
					{props?.data?.text}
				</Typography>
				<Typography
					component='span'
					color='text.primary'
					fontSize={fontSize}
				>
					{props?.data?.label}
				</Typography>
			</Box>
		</Box>
	);
}

export default function CircularWithValueLabel(props: any) {
	return (
		<CircularProgressWithLabel data={props?.data} value={props?.data?.value} />
	);
}
