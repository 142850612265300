// import axiosApiInstance from '../utils/axiosApiInstance';
import instance from '../utils/axiosApiInstance';

const API_BASE_URL = process.env.REACT_APP_API_URL;
const API_BASE_SNOWFLAKE_URL = 'https://snowflake-api.example.com';
const SUGGESTION_API = 'https://beta.diggrowth.ai/';
function getAccessToken() {
	const userData = localStorage?.getItem('userData') || null;
	const parsedUserData = userData ? JSON.parse(userData) : null;
	return parsedUserData ? parsedUserData['access-token'] : null;
}

const ApiManager = {
	get: (obj, accessTokenInHeaders = false, snowflake = false) => {
		if (obj.API_BASE_URL) {
			API_BASE_URL = obj.API_BASE_URL;
		}

		const url = snowflake
			? API_BASE_SNOWFLAKE_URL + obj.url
			: API_BASE_URL + obj.url;
		const headers = accessTokenInHeaders
			? { 'access-token': getAccessToken(), ...obj.headers }
			: { ...obj.headers };

		return instance.get(url, {
			params: { ...obj.data },
			headers: headers,
		});
	},

	post: (
		obj,
		accessTokenInHeaders = false,
		snowflake = false,
		isSuggestionHit = false
	) => {
		let apiUrl = '';
		if (obj.API_BASE_URL) {
			apiUrl = obj.API_BASE_URL;
		} else {
			apiUrl = API_BASE_URL;
		}
		if (isSuggestionHit) {
			apiUrl = SUGGESTION_API;
		}
		if (accessTokenInHeaders) {
			return instance.post(
				snowflake ? API_BASE_SNOWFLAKE_URL + obj.url : apiUrl + obj.url,
				{
					...obj.data,
				},
				{
					headers: {
						'access-token': getAccessToken(),
						...obj.headers,
					},
				}
			);
		} else {
			return instance.post(
				snowflake ? API_BASE_SNOWFLAKE_URL + obj.url : apiUrl + obj.url,
				{
					...obj.data,
				},
				{ headers: { ...obj.headers } }
			);
		}
	},

	put: (obj, accessTokenInHeaders = false, snowflake = false) => {
		if (obj.API_BASE_URL) {
			API_BASE_URL = obj.API_BASE_URL;
		}

		const url = snowflake
			? API_BASE_SNOWFLAKE_URL + obj.url
			: API_BASE_URL + obj.url;
		const headers = accessTokenInHeaders
			? { 'access-token': getAccessToken(), ...obj.headers }
			: { ...obj.headers };

		return instance.put(url, obj.data, { headers });
	},

	delete: (obj, accessTokenInHeaders = false, snowflake = false) => {
		if (obj.API_BASE_URL) {
			API_BASE_URL = obj.API_BASE_URL;
		}

		const url = snowflake
			? API_BASE_SNOWFLAKE_URL + obj.url
			: API_BASE_URL + obj.url;
		const headers = accessTokenInHeaders
			? { 'access-token': getAccessToken(), ...obj.headers }
			: { ...obj.headers };

		return instance.delete(url, {
			data: { ...obj.data },
			headers: headers,
		});
	},
};

export default ApiManager;
