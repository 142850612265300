import { useTheme } from '@mui/styles';

const EditPrimaryIcon = () => {
	const theme: any = useTheme();
	return (
		<>
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='22'
				height='29.917'
				viewBox='0 0 29.888 29.917'
			>
				<g
					id='Group_3229'
					data-name='Group 3229'
					transform='translate(0.25 0.25)'
				>
					<path
						id='Path_1325'
						data-name='Path 1325'
						d='M107.6,92.047a2.081,2.081,0,0,1-2.105,2.075H88.832a2.082,2.082,0,0,1-2.105-2.075V75.326a2.1,2.1,0,0,1,2.105-2.075h8.515l2.694-2.694H88.832A4.8,4.8,0,0,0,84,75.326V92.047a4.81,4.81,0,0,0,4.83,4.8h16.69a4.81,4.81,0,0,0,4.83-4.8V80.807L107.659,83.5v8.546Z'
						transform='translate(-84.002 -67.43)'
						fill={theme.palette.mode === 'dark' ? '#E0E0E0' : '#9149ff'}
						stroke='#fff'
						stroke-width='0.5'
					/>
					<path
						id='Path_1326'
						data-name='Path 1326'
						d='M235.482,18.4,231.7,14.65a2.188,2.188,0,0,0-1.517-.65,2.1,2.1,0,0,0-1.517.619L214.736,28.553a2.141,2.141,0,0,0-.619,1.393l-.248,3.994a2.213,2.213,0,0,0,.619,1.672,2.157,2.157,0,0,0,1.517.619h.124l4.025-.247a2.142,2.142,0,0,0,1.393-.619l13.9-13.9a2.157,2.157,0,0,0,.619-1.517,2.03,2.03,0,0,0-.588-1.548ZM226.6,20.534l3,3-6.967,6.936-3-3Zm-6.812,12.788-3.189.186.186-3.189.9-.9,3,3Zm11.7-11.736-2.973-2.973,1.672-1.672,3,3Z'
						transform='translate(-206.685 -14)'
						fill={theme.palette.mode === 'dark' ? '#E0E0E0' : '#9149ff'}
						stroke='#fff'
						stroke-width='0.5'
					/>
				</g>
			</svg>
		</>
	);
};

export default EditPrimaryIcon;
