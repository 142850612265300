const ShareReportsEndpoints = {
	shareReports(data: any) {
		return {
			url: 'sharedfeature/shared_file',
			data: data.request,
			headers: data.headers,
		};
	},
	scheduleToggle(data: any) {
		return {
			url: 'sharedfeature/schedule_toggle',
			data: data.request,
			headers: data.headers,
		};
	},
	saveSchedule(data: any) {
		return {
			url: 'sharedfeature/schedule_save',
			data: data.request,
			headers: data.headers,
		};
	},
	getSchedule(data: any) {
		return {
			url: 'sharedfeature/get_schedule',
			data: data.request,
			headers: data.headers,
		};
	},
	updateSchedule(data: any) {
		return {
			url: 'sharedfeature/update_schedule',
			data: data.request,
			headers: data.headers,
		};
	},
	deleteSchedule(data: any) {
		return {
			url: 'sharedfeature/disabled_schedule',
			data: data.request,
			headers: data.headers,
		};
	},

	downloadCSV(data: any) {
		return {
			url: 'sharedfeature/download_file',
			data: data.request,
			headers: data.headers,
		};
	},
	uploadPDFtoServer(data: any) {
		return {
			url: 'sharedfeature/pdf_file_email',
			data: data.request,
			headers: data.headers,
		};
	},
	getChildAccounts(data: any) {
		return {
			url: 'sharedfeature/linking_account',
			data: data.request,
			headers: data.headers,
		};
	},
	uploadImage(data: any) {
		return {
			url: 'sharedfeature/upload_logo',
			data: data.reqBody,
			headers: data.headers,
		};
	},
	getLogoUrl(data: any) {
		return {
			url: 'sharedfeature/get_logo',
			data: data.reqBody,
			headers: data.headers,
		};
	},
	deleteLogo(data: any) {
		return {
			url: 'sharedfeature/delete_logo',
			data: data.reqBody,
			headers: data.headers,
		};
	},
};
export default ShareReportsEndpoints;
