import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Grid,
    IconButton,
    useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MultiSelectDropdown from '../../../../components/Dropdown/MultiSelectDropdown';
import Button from '../../../../components/Button/Button';
import { useFilterContext } from './FIltersContext';

interface DropdownItem {
    label: string;
    value: string;
}

interface DropdownData {
    label: string;
    itemList: DropdownItem[];
    selectAllText: string;
    allSelectedPlaceholder: string;
    placeholderText: string;
}

interface ICPFilterProps {
    open: boolean;
    handleClose: () => void;
    data: any;
}

const ICPFilter: React.FC<ICPFilterProps> = ({ open, handleClose, data }) => {
    const theme: any = useTheme();
    const { selectedValues, setSelectedValues } = useFilterContext();
    const [tempSelectedValues, setTempSelectedValues] = useState<string[][]>(selectedValues);

    const initialDropdownData: DropdownData[] = [
        {
            label: 'Select Grade',
            itemList: [
                { label: 'Grade A', value: 'A' },
                { label: 'Grade B', value: 'B' },
                { label: 'Grade C', value: 'C' },
            ],
            selectAllText: 'Select All Grades',
            allSelectedPlaceholder: 'Overall',
            placeholderText: 'Select Grade',
        },
        {
            label: 'Select Industry',
            itemList: [],
            selectAllText: 'Select All Industries',
            allSelectedPlaceholder: 'All Industries',
            placeholderText: 'Select Industries',
        },
        {
            label: 'Select Country',
            itemList: [],
            selectAllText: 'Select All Countries',
            allSelectedPlaceholder: 'All Countries',
            placeholderText: 'Select Countries',
        },
    ];

    const [dropdownData, setDropdownData] = useState<DropdownData[]>(initialDropdownData);

    // Update dropdownData when 'data' prop changes
    useEffect(() => {
        if (data) {
            const industryList = data.mapdataIndustries || [];
            setDropdownData(prevDropdownData => [
                ...prevDropdownData.slice(0, 1),
                {
                    ...prevDropdownData[1],
                    itemList: industryList.map((industry: any) => ({
                        label: industry,
                        value: industry,
                    })),
                },
                {
                    ...prevDropdownData[2],
                    itemList: (data.mapdataCountries || []).map((country: any) => ({
                        label: country,
                        value: country,
                    })),
                },
            ]);
        }
    }, [data]);

    const handleDropdownChange = (selectedItems: DropdownItem[], dropdownIndex: number) => {
        const updatedTempSelectedValues = [...tempSelectedValues];
        updatedTempSelectedValues[dropdownIndex] = selectedItems.map(item => item.value);
        setTempSelectedValues(updatedTempSelectedValues);
    };

    const handleApplyButtonClick = () => {
        setSelectedValues(tempSelectedValues);
        handleClose(); // Optionally close the dialog after applying filters
    };

    return (
        <Dialog fullWidth maxWidth='sm' open={open} onClose={handleClose}>
            <DialogTitle>
                <Typography variant='h4'>Filter</Typography>
            </DialogTitle>
            <DialogContent>
                <Typography>{data.Description}</Typography>
                <Grid container spacing={2}>
                    {dropdownData.map((dropdown, index) => (
                        <Grid item xs={12} key={index} style={{ display: "flex", justifyContent: "space-between" }}>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: '20px',
                                }}
                            >
                                <Typography>{dropdown.label}</Typography>
                                <IconButton
                                    aria-label='close'
                                    onClick={handleClose}
                                    sx={{
                                        position: 'absolute',
                                        right: theme.spacing(1),
                                        top: theme.spacing(1),
                                    }}
                                >
                                    <CloseIcon color='primary' />
                                </IconButton>
                            </div>
                            <div className='Multiselect'>
                                <MultiSelectDropdown
                                    itemList={dropdown.itemList}
                                    selected={dropdown.itemList.filter(item => tempSelectedValues[index].includes(item.value))}
                                    onChange={(selectedItems: any) => handleDropdownChange(selectedItems, index)}
                                    selectAllText={dropdown.selectAllText}
                                    allSelectedPlaceholder={dropdown.allSelectedPlaceholder}
                                    placeholderText={dropdown.placeholderText}
                                />
                            </div>
                        </Grid>
                    ))}
                    <div style={{ display: 'flex', justifyContent: 'center', width: "100%", marginTop: "50px" }}>
                        <Button
                            btnText='Apply'
                            type='button'
                            onClick={handleApplyButtonClick}
                        />
                    </div>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ICPFilter;
