const CurrencyConverter = (num: any) => {
	num = parseFloat(num);
	let convertedData = Math.round((num + Number.EPSILON) * 100) / 100;
	let kFormattedData: any = nFormatter(convertedData);
	return kFormattedData.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export default CurrencyConverter;

const nFormatter = (num: any) => {
	if (num >= 1000000000) {
		return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
	}
	if (num >= 1000000) {
		return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
	}
	if (num >= 1000) {
		return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
	}
	return num;
};
