import { Skeleton } from '@mui/material';
import React from 'react';

interface CustomSkeletonProps {
	width?: string | number;
	height?: any;
	style?: any;
	variant?: any;
}

const CustomSkeleton: React.FC<CustomSkeletonProps> = ({
	width,
	height,
	style,
	variant,
}) => {
	return (
		<Skeleton
			variant={variant}
			width={width}
			height={height}
			style={{ ...style }}
		/>
	);
};

export default CustomSkeleton;
