const predictiveScoringEndpoints = {
    leadScoring(data: any) {
      return {
        url: "leadscoringreport/lead_score",
        headers: data.headers,
        data: data.reqBody,
      };
    },
    leadScoringCSV(data: any) {
      return {
        url: "leadscoringreport/csv_export",
        headers: data.headers,
        data: data.reqBody,
      };
    },
    aiinsights(data: any) {
      return {
        url: "leadscoringreport/insights",
        headers: data.headers,
        data: data.reqBody,
      };
    },
  };
  
  export default predictiveScoringEndpoints;