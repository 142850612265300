import React, { useState } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Typography,
	Grid,
	IconButton,
	useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import Button from '../../../../components/Button/Button';

const IntentFilter = ({ open, handleClose, data, onSelectChange }: any) => {
	const theme: any = useTheme();
	const [select, setSelect]: any = useState();

	const dropdownData = [
		{
			label: 'Select Date Range',
			itemList: ['3 Months', '6 Months', '1 Year'],
		},
	];

	const handleSelectChange = (selectedValue: string) => {
		onSelectChange(selectedValue);
		handleClose()
	};
	return (
		<Dialog fullWidth maxWidth='sm' open={open} onClose={handleClose}>
			<DialogTitle>
				<Typography variant='h4'>Filter</Typography>
			</DialogTitle>
			<DialogContent style={{ padding: '24px' }}>
				<Typography>{data.Description}</Typography>
				<Grid
					xs={12}
					style={{
						borderRadius: '20px',
					}}
				>
					{dropdownData.map((dropdown, index) => (
						<Grid
							key={index}
							xs={12}
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginBottom: '20px',
							}}
						>
							<div
								style={{
									justifyContent: 'center',
									alignContent: 'center',
									flexWrap: 'wrap',
									display: 'flex',
								}}
							>
								{dropdown.label}
								<IconButton
									aria-label='close'
									onClick={handleClose}
									sx={{
										position: 'absolute',
										right: theme.spacing(1),
										top: theme.spacing(1),
									}}
								>
									<CloseIcon color='primary' />
								</IconButton>
							</div>
							<div className='Multiselect'>
								<Dropdown
									valueSelector='value'
									itemList={dropdown.itemList}
									label='Filter By'
									name='Date Type'
									labelName='Filter By'
									defaultValue={data?.dataRange}
									onChange={(value: any) => setSelect(value.target.value)}
								/>
							</div>
						</Grid>
					))}
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<Button
							btnText='Apply'
							type='button'
							onClick={() => handleSelectChange(select)}
						/>
					</div>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default IntentFilter;
