export const AppUtilityFunctions = {
	getRedirectUrl() {
		let redirectUrl = window.location.origin + '/login';
		return redirectUrl;
	},
	calculateTimeDifferenceInSeconds(date1: any, date2: any) {
		const timeDifferenceInMilliseconds = date1 - date2;
		const timeDifferenceInSeconds = Math.floor(
			timeDifferenceInMilliseconds / 1000
		);
		return timeDifferenceInSeconds;
	},

	getCurrentDateTimeWithAddedMinutes(minutesToAdd: any) {
		const currentDateTime = new Date(); // Get the current date and time
		const newDateTime = new Date(
			currentDateTime.getTime() + minutesToAdd * 60 * 1000
		); // Add minutes
		return newDateTime;
	},
	parseDateStringToDate(dateString: any) {
		// Parse the date string into a Date object
		return new Date(dateString);
	},
};
