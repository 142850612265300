import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import DataTable from '../../../../components/DataTable/DataTable';
import { ImportantMetrixTable } from './LeadScoringData';
import './ImportantMetrics.scss';
import CustomSkeleton from '../../../../components/SkeletonLoading/SkeletonLoading';

const ImportantMetrix = (props: any, ref: any) => {
	const theme: any = useTheme();

	return (
		<>
			<div className='reportHeading'>
				<Typography variant='h4' component='span'>
					Important Metrics
				</Typography>
			</div>
			<Grid
				container
				style={{
					padding: '1rem',
					boxShadow: 'none',
					backgroundColor: theme.palette.mode == 'dark' ? '#1A1A1A' : '#F4F7FC',
					borderRadius: '20px',
				}}
			>
				{props?.isLoading ? (
					<CustomSkeleton
						variant={'rectangular'}
						height={500}
						width={'100%'}
						style={{ borderRadius: '20px' }}
					/>
				) : (
					<Grid
						xs={12}
						style={{
							backgroundColor: theme.palette.mode == 'dark' ? 'black' : 'white',
							borderRadius: '20px',
						}}
					>
						<div className='ImportantMetrixTable'>
							<DataTable
								defineMargin={'1rem'}
								data={props?.Data}
								scrollHeight={'100%'}
								columns={ImportantMetrixTable}
								topLeftandRightBorderRadius='10px'
							/>
						</div>
					</Grid>
				)}
			</Grid>
		</>
	);
};

export default ImportantMetrix;
