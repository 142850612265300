import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import CircularWithValueLabel from '../../../../components/CircularProgress/CircularProgressInteractive';
import { leadType } from './LeadScoringData';
import { useEffect, useState } from 'react';
import CustomSkeleton from '../../../../components/SkeletonLoading/SkeletonLoading';

const WinRateOppurtunityScoring = (props: any, ref: any) => {
	const theme: any = useTheme();
	const [selectedLabel, setSelectedLabel]: any = useState('overall');
	const [circularGraphsData, setcircularGraphsData]: any = useState([]);
	const [leadEstimateStateData, setleadEstimatesStateData]: any = useState([]);

	const renderCircularGraph = (data: any, index: any) => (
		<Grid
			key={index}
			item
			xs={6}
			className='circular_graph_container'
			style={{ margin: '20px 0px' }}
		>
			<CircularWithValueLabel data={data} />
		</Grid>
	);

	useEffect(() => {
		props?.setData(
			selectedLabel,
			props?.Data,
			setcircularGraphsData,
			setleadEstimatesStateData
		);
	}, [props, selectedLabel]);

	const handleClick = (item: any) => (
		setSelectedLabel(item.toLowerCase()),
		props?.onGradeTypeChange(item.toLowerCase())
	);

	const renderSVG = (item: string, selected: boolean) => (
		<svg
			width='145'
			height='40'
			viewBox='0 0 145 40'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				d='M0 6.57774C0 4.33752 0 3.21742 0.435974 2.36177C0.819467 1.60912 1.43139 0.997202 2.18404 0.613708C3.03969 0.177734 4.15979 0.177734 6.4 0.177734H120.179C121.006 0.177734 121.419 0.177734 121.815 0.25837C122.165 0.329912 122.505 0.448249 122.825 0.610208C123.185 0.792756 123.508 1.04951 124.156 1.56302L140.676 14.663C142.836 16.3755 143.915 17.2317 144.306 18.2681C144.648 19.1767 144.648 20.1788 144.306 21.0874C143.915 22.1238 142.836 22.98 140.676 24.6924L124.156 37.7924C123.508 38.306 123.185 38.5627 122.825 38.7453C122.505 38.9072 122.165 39.0256 121.815 39.0971C121.419 39.1777 121.006 39.1777 120.179 39.1777H6.4C4.15979 39.1777 3.03969 39.1777 2.18404 38.7418C1.43139 38.3583 0.819467 37.7463 0.435974 36.9937C0 36.1381 0 35.0179 0 32.7777V6.57774Z'
				fill={
					selected
						? 'url(#gradient)'
						: theme.palette.mode === 'dark'
						? '#1A1A1A'
						: '#F4F7FC'
				}
			/>
			<text
				x='40'
				y='25'
				fontSize='14'
				fill={
					selected
						? 'white'
						: theme.palette.mode === 'dark'
						? '#ffffff'
						: 'black'
				}
			>
				{item}
			</text>
			{selected && (
				<defs>
					<linearGradient id='gradient' x1='0%' y1='0%' x2='100%' y2='100%'>
						<stop offset='0%' stopColor='#9149FF' />
						<stop offset='100%' stopColor='#E600FF' />
					</linearGradient>
				</defs>
			)}
		</svg>
	);
	return (
		<Grid style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
			<div className='reportHeading'>
				<Typography variant='h4' component='span'>
					Win Rate
				</Typography>
			</div>
			<Grid
				container
				className='conversion-estimates-container'
				bgcolor={theme.palette.mode == 'dark' ? '#1A1A1A' : '#F4F7FC'}
			>
				{props?.isLoading ? (
					<CustomSkeleton
						variant={'rectangular'}
						height={'auto'}
						width={'66.7%'}
						style={{ borderRadius: '20px' }}
					/>
				) : (
					<Grid
						xs={8}
						style={{
							backgroundColor:
								theme.palette.mode === 'dark' ? 'black' : 'white',
							borderRadius: '25px',
							display: 'flex',
							alignContent: 'center',
							flexWrap: 'wrap',
							padding: '5px',
						}}
					>
						{' '}
						<>
							<Grid xs={3} className='circular_labels_container'>
								{leadType.map((item: string, index: number) => (
									<div
										id={`circle_labels_${index}`}
										key={`circle_labels_${index}`}
										style={{ cursor: 'pointer' }}
										onClick={() => handleClick(item)}
									>
										<Typography
											variant='h6'
											component='span'
											className='gradeLabel'
										>
											{renderSVG(item, selectedLabel === item.toLowerCase())}
										</Typography>
									</div>
								))}
							</Grid>
							<Grid
								xs={9}
								style={{
									display: 'flex',
									justifyContent: 'space-evenly',
									flexDirection: 'row', // Change to row
									flexWrap: 'wrap',
									alignContent: 'center',
									width: '100%', // Adjust width
								}}
							>
								{circularGraphsData.map((data: any, index: any) =>
									renderCircularGraph(data, index)
								)}
							</Grid>
						</>
					</Grid>
				)}
				<Grid className='stats_boxes_and_graph_container' xs={4}>
					<Grid
						container
						style={{
							justifyContent: 'space-evenly',
							display: 'flex',
							flexDirection: 'row',
							gap: '10px',
						}}
					>
						{' '}
						{/* Container for the grid with spacing between items */}
						{leadEstimateStateData.map((item: any, index: number) =>
							props?.isLoading ? (
								<CustomSkeleton
									variant={'rectangular'}
									height={150}
									width={'150px'}
									style={{ borderRadius: '20px' }}
								/>
							) : (
								<Grid item key={item.title}>
									<Grid
										style={{
											justifyContent: 'space-around',
											backgroundColor:
												theme.palette.mode === 'dark' ? 'black' : 'white',
											borderRadius: '20px',
											display: 'flex',
											height: '150px',
											width: '150px',
										}}
									>
										<Grid
											xs={12}
											md={8}
											style={{
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'space-evenly',
											}}
										>
											<Typography
												variant='h3'
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													textAlign: 'center',
													color:
														theme.palette.mode === 'dark' ? '#ffffff' : 'black',
												}}
											>
												{item?.stats}
											</Typography>
											<Typography
												component='span'
												style={{
													display: 'flex',
													justifyContent: 'center',
													alignItems: 'center',
													textAlign: 'center',
													color:
														theme.palette.mode === 'dark' ? '#ffffff' : 'black',
												}}
											>
												{item?.title}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							)
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default WinRateOppurtunityScoring;
