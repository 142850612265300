import React, { useState } from 'react';
import {
	Grid,
	Typography,
	Box,
	useTheme,
	CircularProgress,
	AccordionDetails,
	AccordionSummary,
	Accordion,
} from '@mui/material';
import CustomSkeleton from '../../../../components/SkeletonLoading/SkeletonLoading';
import Confusion from './ConfusionMatrixTabs';
import ConfusionExplained from './ConfusionMatrixDataTabs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ConfusionMatrix: React.FC<any> = (props: any) => {
	const theme = useTheme();
	const [selectedAccordionIndex, setSelectedAccordionIndex] = useState<
		number | null
	>(0);

	const formatPoints = (pointsString: any) => {
		// Split the points string by newline characters (\n)
		const pointsArray = pointsString.split('\n');

		// Create an array of JSX elements, each with a bullet point (•)
		const formattedPoints = pointsArray.map((point: any, index: any) => (
			<Typography key={index} style={{ fontSize: window.innerWidth > 1150 && window.innerWidth < 1850 ? "16px" : "18px" }}>
				• {point.trim()}
			</Typography>
		));

		return formattedPoints;
	};

	const handleChangeAccordion =
		(index: any) => (event: React.SyntheticEvent, isExpanded: boolean) => {
			setSelectedAccordionIndex(isExpanded ? index : null);
		};
	const confusionMatrixData = [
		{
			label: 'True Positive',
			value:
				props?.filterByLead == 'Lead'
					? props?.leadData?.[props?.grade]?.true_positive
					: props?.oppData?.[props?.grade]?.true_positive,
			color: '#E600FF',
			text: `Includes all cases where the actual and predicted outcomes are both "Closed Won". These are correctly identified positive outcomes.`,
			definition: `True Positive: The count of instances where both the predicted and actual outcomes are "Closed Won."`,
			Point1:
				props?.filterByLead == 'Lead'
					? props?.leadData?.[props?.grade]?.True_Positive_info || ''
					: props?.oppData?.[props?.grade]?.True_Positive_info.split('/n')[0],
			Point2: ``,
			Point3: ``,
		},
		{
			label: 'False Negative',
			value:
				props?.filterByLead == 'Lead'
					? props?.leadData?.[props?.grade]?.false_negative
					: props?.oppData?.[props?.grade]?.false_negative,
			color: '#9149ff',
			text: `Encompasses cases where the actual outcome is "Closed Won", but the model incorrectly predicts "Open" or "Closed Lost". These are incorrectly identified as negative outcomes.`,
			definition: `False Negative: The count of instances where the prediction is "Not Closed Won," but the actual outcome is "Closed Won."`,
			Point1:
				props?.filterByLead == 'Lead'
					? props?.leadData?.[props?.grade]?.False_Negative_info || ''
					: props?.oppData?.[props?.grade]?.False_Negative_info.split('/n')[0],
			Point2: ``,
			Point3: ``,
		},
		{
			label: 'False Positive',
			value:
				props?.filterByLead == 'Lead'
					? props?.leadData?.[props?.grade]?.false_positive
					: props?.oppData?.[props?.grade]?.false_positive,
			color: '#9149ff',
			text: `Includes cases where the actual outcome is "Open" or "Closed Lost", but the model incorrectly predicts "Closed Won". These are incorrectly identified as positive outcomes.`,
			definition: `False Positive: The count of instances where the prediction is "Closed Won," but the actual outcome is "Not Closed Won."`,
			Point1:
				props?.filterByLead == 'Lead'
					? props?.leadData?.[props?.grade]?.False_Positive_info || ''
					: props?.oppData?.[props?.grade]?.False_Positive_info,
			Point2: ``,
			Point3: ``,
		},
		{
			label: 'True Negative',
			value:
				props?.filterByLead == 'Lead'
					? props?.leadData?.[props?.grade]?.true_negative
					: props?.oppData?.[props?.grade]?.true_negative,
			color: '#E600FF',
			text: `Encompasses all instances where both the actual and predicted outcomes are either "Open" or "Closed Lost". These are correctly identified negative outcomes.`,
			definition: `True Negative: The count of instances where both the predicted and actual outcomes are "Not Closed Won."`,
			Point1:
				props?.filterByLead == 'Lead'
					? props?.leadData?.[props?.grade]?.True_Negative_info || ''
					: props?.oppData?.[props?.grade]?.True_Negative_info,
			Point2: ``,
			Point3: ``,
		},
	];

	return (
		<Grid container style={{ width: '100%' }}>
			<div
				className='reportHeading'
				style={{ display: 'flex ', justifyContent: 'space-between' }}
			>
				<Typography variant='h4' gutterBottom>
					Confusion Matrix
				</Typography>
			</div>
			<Grid
				container
				alignItems='center'
				bgcolor={theme.palette.mode === 'dark' ? '#1A1A1A' : '#F4F7FC'}
				style={{
					padding: '20px',
					width: '100%',
					display: 'flex',
					// justifyContent: 'center',
					borderRadius: '20px',
					flexDirection: 'row-reverse',
				}}
			>
				{props.isLoading ? (
					<CustomSkeleton
						variant={'rectangular'}
						height={'600px'}
						width={'100%'}
						style={{ borderRadius: '20px' }}
					/>
				) : (<>
				<Grid item xs={6} padding={1}>
					{confusionMatrixData.map((accordion, index) => (
						<Grid item xs={12} key={index} style={{ paddingBottom: '20px' }}>
							<Accordion
								expanded={selectedAccordionIndex === index}
								onChange={handleChangeAccordion(index)}
								style={{
									backgroundColor:
										theme.palette.mode === 'dark' ? 'black' : 'white',
									backgroundImage: 'none',
								}}
							>
								<AccordionSummary expandIcon={<ExpandMoreIcon />}>
									<Typography fontSize={window.innerWidth > 1150 && window.innerWidth < 1850 ? "18px" : "21px"}>{accordion.definition}</Typography>
								</AccordionSummary>
								<AccordionDetails style={{ height: '120px' }}>
									<Typography>{formatPoints(accordion.Point1)}</Typography>
								</AccordionDetails>
							</Accordion>
						</Grid>
					))}
				</Grid>

					<Grid xs={6} >
						<Confusion data={confusionMatrixData} />
					</Grid>
					</>
				)}

			</Grid>
		</Grid>
	);
};

export default React.memo(ConfusionMatrix);
