import React, { useEffect, useState } from 'react';
import { Typography, Grid, Checkbox } from '@mui/material';
import Button from '../../components/Button/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { grey } from '@mui/material/colors';
import slack from './../../assets/Logo/Slack.svg';
import mail from './../../assets/Logo/mail.png';
import { useDateRange } from '../../components/DateTimePicker/DateRangeContext';
import useToast from '../../components/Toast/hooks/useToast';
import useApiService from '../../services/api.service';
import PredictiveScoringEndpoints from './PredictiveScoringEndpoints';

const SlackAuthURL =
	'https://slack.com/oauth/v2/authorize?scope=incoming-webhook&client_id=1010533358502.7344357471764';

const ShareFunctionality = (props: any) => {
	const [selectedPlatform, setSelectedPlatform] = useState('');
	const { dateRange } = useDateRange();
	const toaster: any = useToast();
	const APIService: any = useApiService();
	const [level, setlevel] = useState(() => {
		return parseInt(localStorage.getItem('level') || '1');
	});

	const sendCSV = async (code: any) => {
		const request = {
			reqBody: {
				level: level + 1,
				start_date: dateRange.start_date,
				end_date: dateRange.end_date,
				stage: props?.stagelead ? 'Open' : 'All',
				grade:
					props?.grade == 'overall'
						? 1
						: props?.grade == 'grade a'
						? 2
						: props?.grade == 'grade b'
						? 3
						: props?.grade == 'grade c'
						? 4
						: 1,
				sort: 1,
				slack_code: code,
				recipient_email: [],
			},
		};
		try {
			const res = await APIService.post(
				PredictiveScoringEndpoints.leadScoringCSV(request),
				true
			);
			toaster.addToast({
				message: 'Please check your mail.',
				timeout: 3000,
				type: 'success',
			});
		} catch (err) {
			toaster.addToast({
				message: 'Something went wrong',
				timeout: 3000,
				type: 'error',
			});
			console.error(err);
		} finally {
		}
	};
	function PlatformTypeHTML(data: any) {
		const label = { inputProps: { 'aria-label': data.label } };
		return (
			<Grid display='inline-block'>
				<div style={{ width: 'fit-content' }}>
					<div
						className={`AdPlatform__platform__item ${'AdPlatform__platform__item__selected'}`}
						onClick={() => setSelectedPlatform(data.label)}
						style={{ cursor: 'pointer' }}
					>
						<img
							className='AdPlatform__platform__img'
							src={data.src}
							alt={data.label}
						/>
						<div className='AdPlatform__platform__item__checkbox'>
							<Checkbox
								{...label}
								value={data.label}
								sx={{
									color: grey[800],
									borderRadius: '30px',
									// '&.Mui-checked': {
									// 	color: grey[900],
									// },
								}}
								checked={selectedPlatform === data.label}
								onChange={() => {
									setSelectedPlatform(data.label);
								}}
								icon={<RadioButtonUncheckedIcon />}
								checkedIcon={<CheckCircleIcon />}
								disabled={data.disabled}
							/>
						</div>
					</div>
					<Typography component='div' className='AdPlatform__platform__label'>
						{data.label}
					</Typography>
				</div>
			</Grid>
		);
	}

	const handleContinue = () => {
		if (selectedPlatform === 'Slack') {
			window.location.href = SlackAuthURL;

		} else if (selectedPlatform === 'Mail') {
			props.onMailContinue();
		}
	};

	// const completeauth = () => {
	// 	const url = new URLSearchParams(window.location.search);
    //     sendCSV(url);
	// };

	// useEffect(() => {
	// 	completeauth();
	// }, []);

	return (
		<>
			<div className='AdPlatform__platform'>
				<PlatformTypeHTML src={slack} label='Slack' />
				<PlatformTypeHTML src={mail} label='Mail' />
				<div className='AdPlatform__platform__button'>
					<Button
						btnText='Continue'
						type='button'
						disable={!selectedPlatform}
						onClick={handleContinue}
					/>
				</div>
			</div>
		</>
	);
};

export default ShareFunctionality;
