import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { useTheme } from '@mui/styles';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteIcon from '../../assets/Images/DeletePrimaryIcon';
import EditIcon from '../../assets/Images/EditPrimaryIcon';
import { useClickOutside } from '../../hooks/clickOutside';
import ToolTipOnText from '../Tooltip/Tooltip';

import './Switch.scss';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

interface ISwitchLoaderProps {
	onChange: Function;
	onEditClick?: Function;
	onAddClick?: Function;
	onDeleteClick?: Function;
	checked?: boolean;
	label?: string;
	value?: string;
	labelPlacement?: string;
	disabled?: boolean;
	size?: string;
	color?: string;
	isCustomSwitch?: boolean;
	index?: any;
	slug?: any;
	showAddIcon?: any;
	onTextClick?: any;
	hideEditButton?: any;
	hideDeleteButton?: any;
	onMandatoryChange?: any;
	is_Mandatory?: any;
	showMandatory?: any;
	id?: any;
	selectedChecked?: boolean;
	isChannel?: any;
}

const CustomSwitch = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
	width: 36,
	height: 18,
	padding: 0,
	margin: '2px 10px',
	'& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 0.8,
		transitionDuration: '600ms',
		'&.Mui-checked': {
			transform: 'translateX(18px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				backgroundColor: theme.palette.mode === 'dark' ? '#9149FF' : '#9149FF',
				opacity: 1,
				border: 0,
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.5,
			},
		},
		'&.Mui-focusVisible .MuiSwitch-thumb': {
			color: '#9149FF',
			border: '6px solid #fff',
		},
		'&.Mui-disabled .MuiSwitch-thumb': {
			color:
				theme.palette.mode === 'light'
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		'&.Mui-disabled + .MuiSwitch-track': {
			opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
		},
	},
	'& .MuiSwitch-thumb': {
		boxSizing: 'border-box',
		width: 16,
		height: 16,
	},
	'& .MuiSwitch-track': {
		borderRadius: 24 / 2,
		backgroundColor: theme.palette.mode === 'light' ? '#BDBDBD' : '#39393D',
		opacity: 1,
		transition: theme.transitions.create(['background-color'], {}),
	},
}));

const SwitchButton: React.FC<ISwitchLoaderProps> = (props: any) => {
	const theme: any = useTheme();
	const switchRef: React.MutableRefObject<null> = useRef(null);
	const { isCustomSwitch } = props;

	useClickOutside(switchRef, () => {
		setAnchorEl(null);
	});

	const showCrudIconsOnButton = () => (
		<div>
			<>
				{props.showAddIcon ? (
					<div
						className='Switch__popper__section'
						onClick={() => {
							props.onAddClick();
							setAnchorEl(null);
						}}
						style={{ cursor: 'pointer' }}
					>
						<Typography variant='subtitle1' component='span'>
							{props.isChannel ? 'Add Values' : 'Edit Attribute'}
						</Typography>
						{
							props.isChannel ? (
								<AddCircleOutlineOutlinedIcon color='secondary' />
							) : (
								<EditIcon />
							)
							//  <IconButton className='Switch__icon' component='span'>
							// 	<img className='Switch__icon' src={EditIcon} />
							// </IconButton>
						}
					</div>
				) : null}
				{isCustomSwitch ? (
					<>
						{props.hideEditButton ? null : (
							<div
								className='Switch__popper__section'
								onClick={() => {
									props.onEditClick(props);
									setAnchorEl(null);
								}}
								style={{ cursor: 'pointer' }}
							>
								<Typography variant='subtitle1' component='span'>
									Edit Name
								</Typography>
								<EditIcon />
								{/* <IconButton className='Switch__icon' component='span'>
									<img className='Switch__icon' src={EditIcon} />
								</IconButton> */}
							</div>
						)}
						{props.hideDeleteButton ? null : (
							<div
								className='Switch__popper__section'
								onClick={() => {
									props.onDeleteClick(props);
									setAnchorEl(null);
								}}
								style={{ cursor: 'pointer' }}
							>
								<Typography variant='subtitle1' component='span'>
									Delete
								</Typography>

								<span className='Switch__icon'>
									<DeleteIcon height={'21.45px'} />
								</span>
							</div>
						)}
					</>
				) : null}
				{props.showMandatory ? (
					<div className='Switch__popper__section'>
						<Typography variant='subtitle1' component='span'>
							Mandatory
						</Typography>
						<Checkbox
							{...label}
							style={{ marginLeft: '0.5rem' }}
							onChange={(event: any) => {
								props.onMandatoryChange(event);
							}}
							defaultChecked={!!props.is_Mandatory}
							size={props?.size || 'small'}
							value={props.value || 'checked'}
							color='primary'
						/>
					</div>
				) : null}
			</>
		</div>
	);

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const handleOptionsClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};
	const open = Boolean(anchorEl);

	return (
		<div className='Switch'>
			<ToolTipOnText title={props.label} ellipsisOnOverflow>
				<div
					className='Switch__label'
					onClick={() => {
						if (props.onTextClick) {
							props.onTextClick();
						}
					}}
				>
					{props.label}
				</div>
			</ToolTipOnText>
			<div className='Switch__item'>
				<CustomSwitch
					onChange={(event: any) => {
						props.onChange(event);
					}}
					defaultChecked={!!props.checked}
					checked={!!props.selectedChecked || undefined}
					disabled={props.disabled || false}
					value={props.value || 'checked'}
					size={props?.size || 'small'}
				/>
				{isCustomSwitch || props.showAddIcon ? (
					<IconButton
						aria-label='delete'
						size='small'
						onClick={handleOptionsClick}
					>
						<MoreVertIcon fontSize='inherit' />
					</IconButton>
				) : null}
				{isCustomSwitch || props.showAddIcon ? (
					<Popper
						id='optionsIcons'
						open={open}
						anchorEl={anchorEl}
						className='Switch__popper'
						ref={switchRef}
						style={{
							backgroundColor: theme.palette.background.light,
							border: `1px solid ${theme.palette.neutral.main}`,
						}}
					>
						{showCrudIconsOnButton()}
					</Popper>
				) : null}
			</div>
		</div>
	);
};

export default SwitchButton;
