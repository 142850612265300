import { CircularProgress, Grid, IconButton, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import DataTable from '../../../../components/DataTable/DataTable';
import ToolTipOnText from '../../../../components/Tooltip/Tooltip';
import { Suspense, useEffect, useRef, useState } from 'react';
import CustomSkeleton from '../../../../components/SkeletonLoading/SkeletonLoading';
import SwitchButton from '../../../../components/Switch/Switch';
import ShareThroughEmail from '../../ShareThroughEmail';
import AlertDialog from '../../../../components/Dialog/Dialog';
import useToast from '../../../../components/Toast/hooks/useToast';
import Button from '../../../../components/Button/Button';
import ShareAttachment from '../../../../assets/Images/ShareAttachment';
import useApiService from '../../../../services/api.service';
import PredictiveScoringEndpoints from '../../PredictiveScoringEndpoints';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { useDateRange } from '../../../../components/DateTimePicker/DateRangeContext';
import PageLoader from '../../../../components/pageLoader/pageLoader';
import ShareIcon from '@mui/icons-material/Share';
import ShareFunctionality from '../../ShareFunctionality';

const GradeEstimates = (props: any, ref: any) => {
	const theme: any = useTheme();
	const [Data, setData]: any = useState();
	const [TotalRows, setTotalRows]: any = useState();
	const [isTableExport, setIsTableExport] = useState(true);
	const APIService: any = useApiService();
	const [mailArray, setmailArray] = useState();
	const [hitapi, sethitapi]: any = useState(false);
	const shareAttachmentRef = useRef<any>();
	const ShareThroughEmailref =useRef<any>();
	const toaster: any = useToast();
	const [currentRequest, setCurrentRequest] = useState('');
	const { dateRange } = useDateRange();
	const [level, setlevel] = useState(() => {
		return parseInt(localStorage.getItem('level') || '1');
	});

	const getTableExportNamesArray = (array: any) => {
		setmailArray(array);
	};

	const getTableExportEmailValue = (apihit: boolean) => {
		sethitapi(apihit);
	};

	const shareAttachmentHTML = () => (
		<Grid xs={12} container>
			<Grid sx={{ minWidth: 600, position: 'relative' }}>
				<Grid container padding={'0px 20px'}>
					<ShareThroughEmail
						isTableExport={isTableExport}
						getTableExportNamesArray={getTableExportNamesArray}
						getTableExportEmailValue={getTableExportEmailValue}
						flag={currentRequest}
						toaster={toaster}
						closePopup={(data: any) => {
							if (ShareThroughEmailref.current) {
								ShareThroughEmailref.current.handleClose();
							}
						}}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
	const shareOn = () => (
		<Grid xs={12} container>
			<Grid sx={{ minWidth: 600, position: 'relative' }}>
				<Grid container padding={'0px 20px'}>
					<ShareFunctionality stagelead={props?.stagelead} grade={props?.grade} onMailContinue={openMail}/>
				</Grid>
			</Grid>
		</Grid>
	);

	const openMail = () => {
        // Open ShareThroughEmail dialog
        if (ShareThroughEmailref.current) {
            ShareThroughEmailref.current.handleClickOpen();
        }
        // Close ShareAttachment dialog if it's open
        if (shareAttachmentRef.current) {
            shareAttachmentRef.current.handleClose();
        }
    };

	
	const sendCSV = async () => {
		const request = {
			reqBody: {
				level: level + 1,
				start_date: dateRange.start_date,
				end_date: dateRange.end_date,
				stage: props?.stagelead ? 'Open' : 'All',
				grade:
					props?.grade == 'overall'
						? 1
						: props?.grade == 'grade a'
						? 2
						: props?.grade == 'grade b'
						? 3
						: props?.grade == 'grade c'
						? 4
						: 1,
				sort: 1,
				recipient_email: mailArray,
			},
		};
		try {
			const res = await APIService.post(
				PredictiveScoringEndpoints.leadScoringCSV(request),
				true
			);
			toaster.addToast({
				message: 'Please check your mail.',
				timeout: 3000,
				type: 'success',
			});
		} catch (err) {
			toaster.addToast({
				message: 'Something went wrong',
				timeout: 3000,
				type: 'error',
			});
			console.error(err);
		} finally {
			sethitapi(false);
			if (shareAttachmentRef.current) {
				shareAttachmentRef.current.handleClose();
			}
		}
	};

	const getDataForGrade = (grade: string) => {
		switch (grade) {
			case 'grade a':
				return props?.Data?.A?.lead_score_dict;
			case 'grade b':
				return props?.Data?.B?.lead_score_dict;
			case 'grade c':
				return props?.Data?.C?.lead_score_dict;
			case 'overall':
				return props?.Data?.lead_score_dict;
			default:
				return null;
		}
	};

	const getTotalRows = (grade: string) => {
		switch (grade) {
			case 'grade a':
				return props?.Data?.A?.size;
			case 'grade b':
				return props?.Data?.B?.size;
			case 'grade c':
				return props?.Data?.C?.size;
			case 'overall':
				return props?.Data?.size;
			default:
				return null;
		}
	};

	useEffect(() => {
		setData(getDataForGrade(props?.grade));
		setTotalRows(getTotalRows(props?.grade));
	}, [props]);

	useEffect(() => {
		if (hitapi == true) {
			sendCSV();
		}
	}, [hitapi]);

	return (
		<>
			{hitapi == true && <PageLoader />}
			<>
				<Grid
					className='reportHeading'
					xs={12}
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
					}}
				>
					<Typography variant='h4' component='span'>
						Grade Estimates
					</Typography>
					<Grid
						style={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
							flexWrap: 'wrap',
						}}
					>
						<Typography variant='body2' style={{ marginLeft: '5px' }}>
							Open Opty.
						</Typography>
						<SwitchButton
							checked
							disabled={props.isLoading}
							onChange={(e: any) => {
								props?.stage(e.target.checked);
							}}
						/>
						<div style={{ marginRight: '10px' }}>
							<ToolTipOnText title='Share'>
								<IconButton
									className='pinIcon bookmarkButtons'
									type='button'
									style={{
										height: '30px',
										width: '30px',
									}}
									onClick={() => {
										setCurrentRequest('contactScore');
										if (shareAttachmentRef.current) {
											shareAttachmentRef.current.handleClickOpen();
											shareAttachmentRef.current.flag = 3;
										}
									}}
								>
									<ShareIcon color='primary' height='30px' width='30px' />
								</IconButton>
							</ToolTipOnText>
						</div>
					</Grid>
				</Grid>
				<Grid
					container
					style={{
						padding: '1rem',
						boxShadow: 'none',
						backgroundColor:
							theme.palette.mode == 'dark' ? '#1A1A1A' : '#F4F7FC',
						borderRadius: '20px',
					}}
				>
					{props?.isLoading ? (
						<CustomSkeleton
							variant={'rectangular'}
							height={500}
							width={'100%'}
							style={{ borderRadius: '20px' }}
						/>
					) : null}
					<Grid
						xs={12}
						style={{
							backgroundColor: theme.palette.mode == 'dark' ? 'black' : 'white',
							borderRadius: '20px',
							display: props?.isLoading ? 'none' : 'block',
						}}
					>
						<DataTable
							defineMargin={'1rem'}
							data={Data}
							columns={props?.tablecol}
							showPagination
							serverPagination
							handlePageChange={(event: any) => {
								props.offset(event);
							}}
							handlePerRowsChange={(event: any) => {
								props.limit(event);
							}}
							totalRows={TotalRows}
						/>
					</Grid>
				</Grid>
				<Suspense fallback={<h3>Loading&hellip;</h3>}>
					<AlertDialog
						ref={ShareThroughEmailref}
						sx={{
							'& .MuiDialog-container': {
								'& .MuiPaper-root': {
									width: '100%',
									maxWidth: '660px', // Set your width here
								},
							},
						}}
						title='Share Table'
						customHTMLContent={shareAttachmentHTML}
						onConfirm={() => sethitapi(true)} // Correct invocation
						onDiscard={() => {
							if (ShareThroughEmailref.current) {
								ShareThroughEmailref.current.handleClose();
							}
						}}
						dialogTitleRootClass='shareReportroot'
						hideCloseButton
					/>
				</Suspense>
				<Suspense fallback={<h3>Loading&hellip;</h3>}>
					<AlertDialog
						ref={shareAttachmentRef}
						sx={{
							'& .MuiDialog-container': {
								'& .MuiPaper-root': {
									width: '100%',
									maxWidth: '660px', // Set your width here
								},
							},
						}}
						title='Share On'
						customHTMLContent={shareOn}
						onConfirm={() => sethitapi(true)} // Correct invocation
						onDiscard={() => {
							if (shareAttachmentRef.current) {
								shareAttachmentRef.current.handleClose();
							}
						}}
						dialogTitleRootClass='shareReportroot'
						hideCloseButton
					/>
				</Suspense>
			</>
		</>
	);
};

export default GradeEstimates;
