import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import CustomSkeleton from '../../../../components/SkeletonLoading/SkeletonLoading';
import QuickInsightsTab from './QuickInsightsTab';
import { useFilterContext } from './FIltersContext';
import twoDecimal from '../../../../utils/decimalPercentage';

const QuickInsights: React.FC<any> = (props: any) => {
	const theme: any = useTheme();
	const { selectedValues, limit, offset } = useFilterContext();
	const [selectedDateRange, setSelectedDateRange] = useState('3 Months');
	const handleDateRangeChange = (value: any) => {
		setSelectedDateRange(value);
	};

	const renderICPFitMessage = () => {
		if (
			selectedValues[0].length === 0 &&
			selectedValues[1].length === 0 &&
			selectedValues[2].length === 0
		) {
			return 'Set your ICP';
		} else {
			return (props?.data?.icp_fit_number || 0) + `  `+`(${twoDecimal(props?.data?.icp_fit_percentage)}%)` ;
		}
	};
	
	const QuickData = [
		{
			Percentage: renderICPFitMessage(),
			Text: (
				<span>
					<strong>ICP</strong> fit accounts
				</span>
			),
			filter: true,
			type: 'ICP',
			mapdataCountries: props?.data?.unique_country,
			mapdataIndustries: props?.data?.unique_industry,
		},
		{
			Percentage: props?.data?.qualified_percentage  || 0,
			Text: (
				<span>
					Accounts having <strong>Qualification</strong> Score &ge; 90
				</span>
			),
			filter: false,
			type: 'Qualification',
		},
		{
			Percentage:
				(selectedDateRange == '3 Months'
					? props?.data?.intent_percentage_3_month
					: selectedDateRange == '6 Months'
					? props?.data?.intent_percentage_6_month
					: props?.data?.intent_percentage_1_year) || 0 ,
			Text: (
				<span>
					Accounts with high <strong>Intent</strong> in last{' '}
					{selectedDateRange == '3 Months'
						? '3 Months'
						: selectedDateRange == '6 Months'
						? '6 Months'
						: '1 Year'}
				</span>
			),
			dataRange: selectedDateRange,
			filter: true,
			type: 'Intent',
			infoicon: `Accounts demonstrating High Engagement within the specified date range. By default, this date range is set to 3 months`,
		},
	];

	return (
		<>
			<Grid style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
				<div className='reportHeading'>
					<Typography variant='h4' component='span'>
						Quick Insights
					</Typography>
				</div>
				<Grid
					container
					bgcolor={theme.palette.mode == 'dark' ? '#1A1A1A' : '#F4F7FC'}
					style={{
						padding: '20px 10px',
						display: 'flex',
						justifyContent: 'left',
						borderRadius: '20px',
						// gap: '30px',
						width: '70%',
					}}
				>
					{props?.isLoading ? (
						<CustomSkeleton
							variant={'rectangular'}
							height={'150px'}
							width={'calc(100% - 10px)'}
							style={{ borderRadius: '20px' }}
						/>
					) : (
						QuickData.map((item, index) => (
							<Grid item xs={4} key={index} style={{ flexGrow: 1 }}>
								<QuickInsightsTab
									data={item}
									dateRange={handleDateRangeChange}
								/>
							</Grid>
						))
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default React.memo(QuickInsights);
