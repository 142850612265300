import EditProfileEndPoints from '../containers/EditProfile/EditProfileEndpoints';

export const LogoutSession = {
	async logoutSessionFromKeyCloak() {
		try {
			let userData = localStorage.getItem('userData');
			let parsedUserData;
			let refreshToken;
			try {
				parsedUserData = JSON.parse(userData);
				refreshToken = parsedUserData['refresh-token'];
			} catch (error) {
				console.error('Error parsing userData:', error);
			}

			if (refreshToken) {
				let url =
					process.env.REACT_APP_API_URL +
					EditProfileEndPoints.logoutUser().url +
					`?refresh_token=${refreshToken}`;

				const requestOptions = {
					method: 'GET', // Use the GET HTTP method
					headers: {
						'Content-Type': 'application/json', // Adjust content type as needed
						// You can add additional headers here if required
					},
				};
				await fetch(url, requestOptions);
				window.location.href = '/login';
			}
		} catch (error) {
			console.error('Error:', error);
			// Handle the error
		}
	},
};
