import { Grid, IconButton, Typography } from '@mui/material';
import React, { Suspense, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/styles';
import CustomSkeleton from '../../../../components/SkeletonLoading/SkeletonLoading';
import ToolTipOnText from '../../../../components/Tooltip/Tooltip';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ICPFilter from './ICPFilter';
import IntentFilter from './IntentFIlter';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { width } from '@mui/system';

const QuickInsightsTab: React.FC<any> = (props: any) => {
	const theme: any = useTheme();
	const [dialogOpen, setDialogOpen] = useState(false);
	const [intentDialog, setIntentDialog] = useState(false);
	const [selectedDateRange, setSelectedDateRange] = useState('3 Months');
	const filterOpen = (type: any) => {
		if (type == 'ICP') {
			handleClick();
		} else {
			handleIntentClick();
		}
	};

	const handleDateRangeChange = (value: string) => {
		props?.dateRange(value);
		// Handle any other logic related to the selected date range
	};
	const handleClick = () => {
		setDialogOpen(true);
	};

	const handleClose = () => {
		setDialogOpen(false);
	};

	const handleIntentClick = () => {
		setIntentDialog(true);
	};

	const handleIntentClose = () => {
		setIntentDialog(false);
	};

	return (
		<>
			<Grid
				style={{
					backgroundColor: theme.palette.mode == 'dark' ? 'black' : 'white',
					padding: '20px',
					margin: '0px 10px',
					borderRadius: '20px',
					height: '150px',
					display: 'flex',
					// justifyContent: "space-evenly",
					flexDirection: 'column',
				}}
			>
				<Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
					<Typography
						variant='h3'
						style={{ color: '#9149ff', marginBottom: '20px' }}
					>{(props?.data?.Percentage == "Set your ICP") ? 
						props?.data?.Percentage : (props.data.type === 'ICP' ? props?.data?.Percentage || 0  : props?.data?.Percentage+  "%" || 0 + "%")}
					</Typography>
					{props?.data?.filter ? (
						<ToolTipOnText
							title='Filter'
							style={{ display: 'flex', justifyContent: 'flex-end' }}
						>
							<IconButton
								className='pinIcon bookmarkButtons'
								type='button'
								style={{
									height: '30px',
									width: '30px',
								}}
								onClick={() => filterOpen(props?.data?.type)}
							>
								<FilterAltIcon color='primary' height='30px' width='30px' />
							</IconButton>
						</ToolTipOnText>
					) : null}
				</Grid>
				<Grid style={{display: "flex"}}>
				{props?.data?.Text}
				</Grid>
			</Grid>
			{props.data.type === 'ICP' && (
				<ICPFilter
					open={dialogOpen}
					handleClose={handleClose}
					data={props.data}
				/>
			)}
			{props.data.type === 'Intent' && (
				<IntentFilter
					open={intentDialog}
					handleClose={handleIntentClose}
					data={props.data}
					onSelectChange={handleDateRangeChange}
				/>
			)}
		</>
	);
};

export default React.memo(QuickInsightsTab);
