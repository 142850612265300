import { Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import CustomSkeleton from '../../../../components/SkeletonLoading/SkeletonLoading';
import DataProfile from './DataProfile';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import { TableColumns } from '../LeadEstimates/LeadScoringData';
import ToolTipOnText from '../../../../components/Tooltip/Tooltip';
import DataQualityCard from '../../../../components/DataQualityCard/DataQalityCard';

const DataProfiling: React.FC<any> = (props: any) => {
	const theme: any = useTheme();
	const DataProfilingData = [
		{
			cardsData: [
				{
					name: 'Industry',
					status: props?.data?.unknown_industry,
					discription: `Industry field is useful in predictive scoring and ${props?.data?.unknown_industry}% of lead records have blank industry field.`,
					range: { value: props?.data?.unknown_industry, total: 100 },
				},
				{
					name: 'Title',
					status: props?.data?.unknown_title,
					discription: `Title is useful in understanding buyer persona and helpful for personalization and ${props?.data?.unknown_title}% of lead records have blank title.`,
					range: { value: props?.data?.unknown_title, total: 100 },
				},
				{
					name: 'Revenue',
					status: props?.data?.missing_revenue_percentage,
					discription: `Revenue is helpful in deciding the Ideal Customer Profile (ICP), though ${props?.data?.missing_revenue_percentage}% of the accounts currently have a null revenue field.`,
					range: { value: props?.data?.missing_revenue_percentage, total: 100 },
				},
				{
					name: 'Employee Count',
					status: props?.data?.missing_employee_percentage,
					discription: `Employee count helps in assessing company size, but we’ve found that ${props?.data?.missing_employee_percentage}% of the accounts are missing this information.`,
					range: { value: props?.data?.missing_employee_percentage, total: 100 },
				},
				{
					name: 'Country',
					status: props?.data?.unknown_country,
					discription: `Country with respect to each lead. This status reflects that ${props?.data?.unknown_country}% data has missing values`,
					range: { value: props?.data?.unknown_country, total: 100 },
				},
				{
					name: 'Stage Misalignment (MA to CRM)',
					status: "table",
					discription: 'Discrepancy in stages Between Marketing Automation and CRM Systems',
					tabledata: props?.data?.get_missing_opportunities,
					TableColumns: [
						{
							name: 'Email',
							selector: (row: any) => row.email,
							center: true,
							cell: (row: any) => (
							row.email
							),
						},
						{
							name: 'Lead Status',
							selector: (row: any) => row.lead_status,
							center: true,
							cell: (row: any) => (
								row.lead_status
							),
						},
					],
					TotalRows: props?.data?.missing_opportunities_size,
					Loading: props?.isLoading,
					InnerDescription: `We have identified a discrepancy where the lead status in the marketing automation system is marked as 'client' or 'customer' yet the stage is not updated to "closed won" in the CRM system. This misalignment needs to be addressed to ensure consistency between our marketing and sales data.`,
				},
			],
		},
	];


	return (
		<Grid style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
			<div className='reportHeading'>
				<Typography variant='h4' component='span'>
					Database Health
				</Typography>
			</div>
			<Grid
				container
				bgcolor={theme.palette.mode == 'dark' ? '#1A1A1A' : '#F4F7FC'}
				style={{
					padding: '20px',
					display: 'flex',
					justifyContent: 'left',
					borderRadius: '20px',
				}}
			>
				
					<CustomSkeleton
						variant={'rectangular'}
						height={'500px'}
						width={'100%'}
						style={{ borderRadius: '20px' , display : props?.isLoading ? "block" : "none"  , zIndex : 1 }}
					/>
					<div style={{ display : props?.isLoading ? "none" : "block" }}>
					{DataProfilingData.map(
						(data, index) => (
							<DataQualityCard
								key={`card_${index}`}
								cardsData={data.cardsData}
							/>
						)
					)}
					</div>
			</Grid>
		</Grid>
	);
};

export default React.memo(DataProfiling);
