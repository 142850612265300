import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/styles';
import useToast from '../../../components/Toast/hooks/useToast';
import PredictiveScoringEndpoints from '../PredictiveScoringEndpoints';
import useApiService from '../../../services/api.service';
import ScoreBoard from './ScoreBoards/ScoreBoard';

const HumanAI: React.FC<any> = (props: any) => {
	const theme: any = useTheme();
	const toaster: any = useToast();
	const APIService: any = useApiService();
	const [scoreBoardData, setScoreBoardData] = useState();
	const [stages, setStages] = useState(['All']);
	const [Connector, setConnector] = useState();
	const [selectedStages, setselectedStages] = useState('All');
	const [isLoading, setisLoading]: any = useState(true);
	const [tableLimit, setTableLimit]: any = useState(10);
	const [tableOffset, setTableOffset]: any = useState(1);
	const handleStageChange = (data: any) => {
		setselectedStages(data);
	};
	const handleLimit = (data: any) => {
		setTableLimit(data);
		setTableOffset(Math.ceil((tableOffset * tableLimit) / data));
	};

	const handleOffset = (data: any) => {
		setTableOffset(data);
	};
	const getLeadScoringData = async () => {
		try {
			setisLoading(true);
			const request = {
				reqBody: {
					level: 4,
					limit: tableLimit,
					offset: tableOffset,
					start_date: props.dateRange.start_date,
					end_date: props.dateRange.end_date,
					stage: selectedStages,
				},
			};
			const res = await APIService.post(
				PredictiveScoringEndpoints.leadScoring(request),
				true
			);
			setData(res);
		} catch (err) {
			toaster.addToast({
				message: 'Kindly, enable Train Model',
				timeout: 3000,
				type: 'error',
			});
			console.error(err);
		} finally {
			setisLoading(false);
		}
	};
	useEffect(() => {
		getLeadScoringData();
	}, [props?.dateRange, tableLimit, tableOffset, selectedStages]);

	const setData = (res: any) => {
		setScoreBoardData(res?.lead_score);
		setStages(res?.stages);
		setConnector(res?.platform_ma);
	};
	return (
		<>
			<Grid
				container
				className='tab_container'
				style={{ marginBottom: '50px' }}
			>
				<ScoreBoard
					connector={Connector}
					Stages={stages}
					onFilterChange={handleStageChange}
					Data={scoreBoardData}
					isLoading={isLoading}
					limit={handleLimit}
					offset={handleOffset}
				/>
			</Grid>
		</>
	);
};

export default React.memo(HumanAI);
