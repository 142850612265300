import { Grid } from '@mui/material';
import * as barHigh from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import '../../../../components/BargraphHichart/Bargraph.scss';
import { useTheme } from '@mui/styles';

import { memo, useEffect, useState } from 'react';
import React from 'react';
import exportData from 'highcharts/modules/export-data';
export const handleModelNames = (model: any) => {
	switch (model) {
		case 'linear_model':
			return 'Linear';
			break;
		case 'linear':
			return 'Linear';
			break;
		case 'first_touch':
			return 'First Touch';
			break;
		case 'last_touch':
			return 'Last Touch';
			break;
		case 'last_interaction':
			return 'Last Interaction';
			break;

		case 'u_shape':
			return 'U Shape';
			break;
		case 'w_shape':
			return 'W Shaped';
			break;
		case 'u_shaped':
			return 'U Shape';
			break;
		case 'w_shaped':
			return 'W Shaped';
			break;
		case 'full_path_model':
			return 'Full Path';
			break;
		case 'full_path':
			return 'Full Path';
			break;

		case 'lead_creation_touch':
			return 'Lead Creation';
			break;
		case 'lead_creation':
			return 'Lead Creation';
			break;
		case 'last_non-direct':
			return 'Last Non Direct';
			break;
		case 'last_non_direct':
			return 'Last Non Direct';
			break;
		case 'markov':
			return 'Markov';
			break;

		case 'shapely':
			return 'Shapely';
			break;
		case 'data_driven_attribution':
			return 'Data Driven';
			break;
		default:
			return model;
			break;
	}
};

const BarGraphLeadsByChannel = memo((props: any) => {
	const [chartOptions, setChartOptions]: any = useState(false);

	useEffect(() => {
		setChartOptions(options);
	}, [props]);
	(function (H: any) {
		H.addEvent(H.Chart, 'render', function () {
			// @ts-ignore
			// @ts-ignore
			if (this?.dataTableDiv && !props.showTable) {
				// @ts-ignore
				this.dataTableDiv.innerHTML = this?.getTableforBar();
				// @ts-ignore
				this.dataTableDiv.style.backgroundColor =
					theme.palette.background.light;
			}
		});
	})(barHigh);

	exportData(barHigh);

	NoDataToDisplay(barHigh);
	const theme: any = useTheme();

	var H: any = barHigh,
		{ pick, fireEvent } = H,
		//   @ts-ignore
		htmlencode = function (html) {
			return html
				.replace(/&/g, '&amp;')
				.replace(/</g, '&lt;')
				.replace(/>/g, '&gt;')
				.replace(/"/g, '&quot;')
				.replace(/'/g, '&#x27;')
				.replace(/\//g, '&#x2F;');
		};

	H.Chart.prototype.getTableforBar = function (useLocalDecimalPoint: any) {
		var html = '<table id="highcharts-data-table-' + this?.index + '">',
			options = this?.options,
			decimalPoint = useLocalDecimalPoint ? (1.1).toLocaleString()[1] : '.',
			//   @ts-ignore

			useMultiLevelHeaders = pick(
				options?.exporting?.useMultiLevelHeaders,
				true
			),
			rows = this?.getDataRows(useMultiLevelHeaders),
			rowLength = 0,
			topHeaders = useMultiLevelHeaders ? rows.shift() : null,
			subHeaders = rows.shift(),
			// Compare two rows for equality
			//   @ts-ignore

			isRowEqual = function (row1: any, row2: any) {
				var i = row1?.length;
				if (row2.length === i) {
					while (i--) {
						if (row1[i] !== row2[i]) {
							return false;
						}
					}
				} else {
					return false;
				}
				return true;
			},
			// Get table cell HTML from value
			//   @ts-ignore

			getCellHTMLFromValue = function (
				tag: any,
				classes: any,
				attrs: any,
				type: any,
				value: any
			) {
				var val = pick(value, ''),
					className = 'text' + (classes ? ' ' + classes : '');
				// Convert to string if number
				if (typeof val === 'number' || typeof val == 'string') {
					//-------------------------------------------------------
					//   @ts-ignore
					if (
						type == 'revenue' ||
						type == 'current_pipeline' ||
						type == 'total_pipeline'
					) {
						typeof val == 'number'
							? (val = '$' + handleModelNames(val))
							: (val = val == 'Category' ? 'Channels' : handleModelNames(val));
						//-------------------------------------------------------
					} else {
						val = handleModelNames(val);
					}
					val = val.toString();
					if (decimalPoint === ',') {
						val = val?.replace('.', decimalPoint);
					}
					className = 'number';
				} else if (!value) {
					className = 'empty';
				}

				return (
					'<' +
					tag +
					(attrs ? ' ' + attrs : '') +
					' class="' +
					className +
					' " ' +
					(theme.palette.mode == 'dark'
						? ' style="' + 'border:1px solid rgba(255, 255, 255, 0.5)'
						: ' style="' + 'border:1px solid #bebebe') +
					'">' +
					val +
					'</' +
					tag +
					'>'
				);
			},
			// Get table header markup from row data
			//   @ts-ignore

			getTableHeaderHTML = function (
				topheaders: any,
				subheaders: any,
				rowLength: any
			) {
				var html = '<thead>',
					i = 0,
					len = rowLength || (subheaders && subheaders?.length),
					next,
					cur,
					curColspan = 0,
					rowspan;
				// Clean up multiple table headers. Chart.getDataRows() returns two
				// levels of headers when using multilevel, not merged. We need to
				// merge identical headers, remove redundant headers, and keep it
				// all marked up nicely.
				if (
					useMultiLevelHeaders &&
					topheaders &&
					subheaders &&
					!isRowEqual(topheaders, subheaders)
				) {
					html += '<tr class=rows>';
					for (; i < len; ++i) {
						cur = i == 0 ? 'Models' : topheaders[i];
						next = topheaders[i + 1];
						if (cur === next) {
							++curColspan;
						} else if (curColspan) {
							// Ended colspan
							// Add cur to HTML with colspan.
							html += getCellHTMLFromValue(
								'th',
								'highcharts-table-topheading',
								'scope="col" ' + 'colspan="' + (curColspan + 1) + '"',
								props.currentMatric,
								cur
							);
							curColspan = 0;
						} else {
							// Cur is standalone. If it is same as sublevel,
							// remove sublevel and add just toplevel.
							if (cur === subheaders[i]) {
								//   @ts-ignore

								if (options?.exporting?.useRowspanHeaders) {
									rowspan = 2;
									delete subheaders[i];
								} else {
									rowspan = 1;
									subheaders[i] = '';
								}
							} else {
								rowspan = 1;
							}
							html += getCellHTMLFromValue(
								'th',
								'highcharts-table-topheading',
								'scope="col"' +
									(rowspan > 1
										? ' valign="top" rowspan="' + rowspan + '"'
										: ''),
								props.currentMatric,
								cur
							);
						}
					}
					html += '</tr>';
				}
				// Add the subheaders (the only headers if not using multilevels)
				if (subheaders) {
					html += '<tr class=rows>';
					for (i = 0, len = subheaders.length; i < len; ++i) {
						if (typeof subheaders[i] !== 'undefined') {
							html += getCellHTMLFromValue(
								'th',
								null,
								'scope="col"',
								props.currentMatric,
								subheaders[i]
							);
						}
					}
					html += '</tr>';
				}
				html += '</thead>';
				return html;
			};
		// Add table caption
		//   @ts-ignore

		if (options.exporting.tableCaption !== false) {
			//   @ts-ignore

			html +=
				'<caption class="highcharts-table-caption">' +
				pick(
					options?.exporting?.tableCaption,
					options?.title?.text
						? //   @ts-ignore

						  htmlencode(options.title.text)
						: 'Chart'
				) +
				'</caption>';
		}
		// Find longest row
		for (var i = 0, len = rows.length; i < len; ++i) {
			if (rows[i].length > rowLength) {
				rowLength = rows[i].length;
			}
		}
		// Add header

		//   @ts-ignore

		html += getTableHeaderHTML(
			topHeaders,
			subHeaders,
			Math.max(rowLength, subHeaders?.length)
		);
		// Transform the rows to HTML
		html += '<tbody class=body>';
		rows.forEach(function (row: any) {
			html += '<tr class=rows>';
			for (var j = 0; j < rowLength; j++) {
				// Make first column a header too. Especially important for
				// category axes, but also might make sense for datetime? Should
				// await user feedback on this.
				html += getCellHTMLFromValue(
					j ? 'td' : 'th',
					null,
					j ? '' : 'scope="row"',
					props.currentMatric,
					row[j]
				);
			}
			html += '</tr>';
		});
		html += '</tbody></table>';
		var e = {
			html: html,
		};
		fireEvent(this, 'afterGetTable', e);
		return e.html;
	};

	const options = {
		chart: {
			type: props?.graphType || 'column',
			plotBackgroundColor: theme.palette.mode === 'dark' ? 'black' : 'white',
			backgroundColor: theme.palette.mode === 'dark' ? 'black' : 'white',
			borderColor: theme.palette.mode === 'dark' ? 'black' : 'white',
			plotShadow: false,
			height: 400,
			className: 'highcharts-color-0',
			renderTo: 'container',
			scrollablePlotArea: {
				minWidth:
					props.categories.length <= 15
						? 500
						: props.categories.length > 15 && props.categories.length <= 30
						? 3000
						:  props.categories.length > 30 && props.categories.length <= 45
						? 500 : 7000,
				scrollPositionX: 0,
			},
		},
		title: {
			style: {
				textTransform: 'capitalize',
				fontSize: '22px',
				fontWeight: 'bold',
				color: theme.palette.text.primary,
				fontFamily:
					"lato, BlinkMacSystemFont, 'Segoe UI', Loto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
			},
			text: props.categories.length ? props.title : '',
		},
		credits: {
			enabled: false,
		},
		xAxis: {
			min: 0,
			max: props?.categories?.length - 1,
			categories: props.categories,
			title: {
				text: props.xAxisTitle ? props.xAxisTitle : null,
				style: {
					color: theme.palette.text.primary,
				},
			},
			scrollbar: {
				enabled: true,
			},
			labels: {
				style: {
					color: theme.palette.text.primary,
				},
			},
		},
		yAxis: {
			type: 'logarithmic',
			minorTickInterval: 0,
			gridLineWidth: 0,
			title: {
				text: props.yAxisTitle ? props.yAxisTitle : null,
				style: {
					color: theme.palette.text.primary,
				},
			},
			y: -20,
			labels: {
				style: {
					color: theme.palette.text.primary,
				},
			},
		},
		tooltip: {
			shared: false,
			backgroundColor: 'white',
			borderColor: 'black',
			borderRadius: 10,
			borderWidth: 1,
			allowHTML: true,
			formatter: function (): any {
				//@ts-ignore
				var indexOfX = this.point.x;
				// @ts-ignore
				if (props.isFromCustomReport && !props.isLifeCycleStage) {
					return (
						'<span style="color:{series.color}">' +
						// @ts-ignore
						// `${props?.series[0]?.name}`
						`${props.yAxisTitle}` +
						'<b>' +
						' : ' +
						// @ts-ignore
						`${this.y}` +
						'</b>' +
						'</span>'
					);
				} else if (
					props.currentMatric == 'revenue' ||
					props.currentMatric == 'current_pipeline' ||
					props.currentMatric == 'total_pipeline'
				) {
					return (
						'<span style="color:{series?.color}">' +
						// @ts-ignore

						`${handleModelNames(this.series.name)}` +
						'<b>' +
						' : ' +
						'$' +
						// @ts-ignore
						`${this.y}` +
						'</b>' +
						'</span>'
					);
				} else {
					return (
						'<span style="color:{series?.color}">' +
						// @ts-ignore

						`${this.x}` +
						'<b>' +
						' : ' +
						// @ts-ignore
						`${this.y}` +
						'</b>' +
						'</span>'
					);
				}
			},
		},
		plotOptions: {
			column: {
				borderColor: null,
				borderWidth: 0,
				dataLabels: {
					formatter: function () {
						return props?.percentage;
					},
					color: 'white',
					enabled: true,
				},
			},
		},
		series: props.series || [],
		lang: {
			noData: props.noDataMessage || 'There is no data in the graph ',
		},
		noData: {
			style: {
				fontWeight: 'bold',
				fontSize: '15px',
				color: theme.palette.text.primary,
			},
		},
		colors: props.colors || [
			'#6C63FF',
			'#00E9FA',
			'#FF9C7F',
			'#B14708',
			'#213142',
			'#FF2366',
			'#6697CC',
			'#FFD950',
			'#297373',
		],
		legend: {
			itemDistance: 5,
			itemMarginTop: 5,
			itemMarginBottom: 5,
			itemWidth: 150,
			style: {
				margin: 'auto',
				textAlign: 'center',
			},
			itemStyle: {
				textAlign: 'right',
				fontSize: '13px',
				fontFamily: 'Lato !important',
				color: theme.palette.text.primary,
				fontWeight: '400 !important',
			},
			itemHoverStyle: {
				color: '#707070',
			},
			itemHiddenStyle: {
				color: '#707070',
			},
			// @ts-ignore

			labelFormatter: function () {
				// @ts-ignore
				return handleModelNames(this.name);
			},
		},
	};
	return (
		<>
			<Grid
				className='bargraph'
				bgcolor={theme.palette.mode === 'dark' ? 'black' : 'white'}
			>
				{chartOptions && (
					<HighchartsReact
						// id="pieId"
						highcharts={barHigh}
						options={chartOptions}
					/>
				)}
			</Grid>
		</>
	);
});
export default React.memo(BarGraphLeadsByChannel);
