import { Subject } from 'rxjs';
let state = {};
const subject = new Subject();

const headerService = {
	subscribe: (update: any) => subject.subscribe(update),
	unsubscribe: () => subject.unsubscribe(),
	emit: (data: any) => {
		state = data;
		subject.next(state);
	},
	setData: (d: any) => subject.next(d),
	clearData: () => subject.next(),
	getData: () => subject.asObservable(),
	state,
};

export default headerService;
