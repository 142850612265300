import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import DataTable from '../../../../components/DataTable/DataTable';
import ToolTipOnText from '../../../../components/Tooltip/Tooltip';
import ActualPredictiveGraph from './ActualVSPredictiveGraph';
import { color } from 'highcharts';
import Dropdown from '../../../../components/Dropdown/Dropdown';
import { useState } from 'react';
import { width } from '@mui/system';
import CustomSkeleton from '../../../../components/SkeletonLoading/SkeletonLoading';

const ActualPredictive = (props: any, ref: any) => {
	const theme: any = useTheme();
	const [selectGrade, setSelectGrade] :any = useState();


	const seriesData = [
		{
			name: props?.actuallabel,
			data: props?.actualdata?.slice(0, 12),
			type: 'spline',
			color: '#9747FF',
			showInLegend: true,
		},
		{
			name: props?.predictedlabel,
			data: props?.predicteddata,
			type: 'spline',
			color: '#E600FF',
			showInLegend: true,
		},
	];

	const handleGradeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const selectedGrade = event.target.value as string;
		setSelectGrade(selectedGrade);
		props.onGradeChange(selectedGrade.toLowerCase(), props.index);
	};

	return (
		<>
			<div className='reportHeading'>
				<Typography variant='h4' component='span'>
					{props.title}
				</Typography>
			</div>
			<Grid
				container
				style={{
					padding: '1rem',
					boxShadow: 'none',
					backgroundColor: theme.palette.mode == 'dark' ? '#1A1A1A' : '#F4F7FC',
					borderRadius: '20px',
				}}
			>
				{props?.isLoading ? (
					<CustomSkeleton
						variant={'rectangular'}
						height={500}
						width={'100%'}
						style={{ borderRadius: '20px' }}
					/>
				) : (
					<Grid
						xs={12}
						style={{
							backgroundColor: theme.palette.mode == 'dark' ? 'black' : 'white',
							borderRadius: '20px',
							padding: '1rem',
						}}
					>
						<>
							<Grid
								xs={12}
								style={{
									margin: '20px 20px 0px 0px',
									display: 'flex',
									justifyContent: 'flex-end',
								}}
							>
								<Grid style={{ width: '200px' }}>
									<Dropdown
										valueSelector='value'
										itemList={['Overall', 'Grade A', 'Grade B', 'Grade C']}									
										label='Filter By'
										name='Date Type'
										onChange={handleGradeChange}
										labelName='Filter By'
										defaultValue={'Overall'}
									/>
								</Grid>
							</Grid>
							<ActualPredictiveGraph
								seriesData={seriesData}
								xAxisCategories={props?.datecategories}
							/>
						</>
					</Grid>
				)}
			</Grid>
		</>
	);
};

export default ActualPredictive;
