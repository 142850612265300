import noDataFound from '../../assets/Images/NoDataFound.png';
import SortingIcon from '../../assets/Images/sorting-icon.svg';
import NoRecord from '../NoRecord/NoRecord';
import PageLoader from '../pageLoader/pageLoader';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import { useTheme } from '@mui/styles';
import { minHeight } from '@mui/system';
import React from 'react';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';

import './DataTable.scss';

interface ITableLoaderProps {
	columns: any;
	data: any;
	className?: any;
	itemsPerPage?: number;
	scrollHeight?: any;
	handleSort?: any;
	sortServer?: any;
	showPagination?: boolean;
	striped?: boolean;
	collapsableTable?: boolean;
	refreshTable?: any;
	style?: any;
	totalRows?: any;
	serverPagination?: any;
	customStyles?: any;
	defineMargin?: any;
	linearLoader?: boolean;
	noDataComponent?: any;
	isSetDataEmpty?: boolean;
	defaultSortFieldId?: any;
	borderTopLeftRadius?: any;
	defaultSortAsc?: any;
	page?: any;
	topLeftandRightBorderRadius?: any;
	handlePageChange?: any;
	handlePerRowsChange?: any;
}

const Table: React.FC<ITableLoaderProps> = (props: any) => {
	const theme: any = useTheme();
	const [columns, setColumns] = useState(props?.columns);
	const [data, setData] = useState(props.data);
	const [tableOpen, setTableOpen] = useState(true);
	const [showData, setShowData] = useState(true);
	const [loading, setLoading] = useState(false);
	const [noOfRows, setNoOfRows] = useState(1);
	useEffect(() => {
		if (data !== props?.data && props?.data?.length > 0) {
			setData([...props.data]);
		} else if (props?.data?.length === 0) {
			setData([]);
		}
	}, [props.data]);
	useEffect(() => {
		setLoading(true);
		setTimeout(
			() => {
				setLoading(false);
			},
			props.linearLoader ? 400 : 0
		);
	}, [props.refreshTable]);

	// useEffect(() => {
	// 	setLoading(true);
	// 	setTimeout(() => {
	// 		<PageLoader />;
	// 		setLoading(false);
	// 		setNoOfRows(1);
	// 	}, 500);
	// }, [noOfRows]);

	const showCollapseTable = (): void => {
		if (props.collapsableTable) {
			const data = props.columns;
			if (data[data.length - 1].id === 'collapse') {
				data.pop();
			}
			data.push({
				name: (
					<IconButton onClick={collapseTableHandler}>
						{tableOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
					</IconButton>
				),
				id: 'collapse',
				button: true,
				width: '75px',
			});
			setColumns([...data]);
		}
	};

	useEffect(() => {
		showCollapseTable();
	}, [props.columns]);

	useEffect(() => {
		showCollapseTable();
	}, [tableOpen]);

	useEffect(() => {
		if (columns !== props?.columns) {
			setColumns(props.columns);
		}
	}, [props.columns]);
	const collapseTableHandler = () => {
		const table = tableOpen;
		setTableOpen(!table);
		setShowData(!table);
	};

	const customStyles = {
		rows: {
			style: {
				borderWidth: '0px !important',
				padding: '0.5rem 0rem !important',
			},
		},
		headCells: {
			style: {
				letterSpacing: '0.13px',
				fontSize: '15px !important',
				fontWeight: 'bold !important',
				paddingLeft: '1.4rem',
				paddingRight: '1.4rem',
				whiteSpace: 'normal !important',
			},
		},
		cells: {
			style: {
				letterSpacing: '0.13px',
				fontSize: '14px !important',
				fontWeight: 'medium !important',
				paddingLeft: '1.4rem',
				paddingRight: '1.4rem',
			},
		},
	};

	const paginationComponentOptions = {
		rowsPerPageText: 'Row per page:',
	};

	return (
		<div
			style={{
				margin: `${props.defineMargin ? props.defineMargin : '1.5rem'}`,
				height: `${props.scrollHeight && showData ? props.scrollHeight : ''}`,
				borderBottomLeftRadius: props.bottomLeftandRightBorderRadius ? '20px' : '',
				borderBottomRightRadius:  props.bottomLeftandRightBorderRadius ? '20px' : '',
				borderTopLeftRadius: props.topLeftandRightBorderRadius ? '20px' : '',
				borderTopRightRadius: props.topLeftandRightBorderRadius ? '20px' : '',
				background: theme.palette.background.light,
				...props.style,
			}}
			className={theme.palette.mode === 'dark' ? 'DatatableDark' : 'Datatable'}
		>
			<DataTable
				className={props.className}
				fixedHeader={true}
				fixedHeaderScrollHeight={props.scrollHeight || '400px'}
				columns={columns}
				sortIcon={<ImportExportIcon />}
				onSort={(event: any, sortDirection: any) => {
					if (props?.sortServer) {
						props.handleSort(event, sortDirection);
					}
				}}
				// onSort={handleSort}
				sortServer={props.sortServer ? true : false}
				data={showData ? data : []}
				// noDataComponent={props.noDataComponent || NODATAFOUND()}
				noDataComponent={
					tableOpen
						? props.noDataComponent
							? props.noDataComponent
							: NODATAFOUND(props?.page, props?.scrollHeight)
						: null
				}
				progressComponent={
					props.linearLoader ? (
						<div style={{ width: '100%' }}>
							<LinearProgress />
						</div>
					) : (
						<PageLoader />
					)
				}
				paginationTotalRows={props?.serverPagination ? props.totalRows : ''}
				onChangeRowsPerPage={(event: any) => {
					if (props?.serverPagination) {
						props.handlePerRowsChange(event);
					}
				}}
				onChangePage={(event: any) => {
					if (props?.serverPagination) {
						props.handlePageChange(event);
					}
				}}
				paginationServer={props?.serverPagination ? true : false}
				paginationComponentOptions={paginationComponentOptions}
				pagination={props.showPagination || false}
				striped={props.striped !== undefined ? props.striped : true}
				customStyles={props.customStyles ? props.customStyles : customStyles}
				persistTableHead
				progressPending={loading}
				defaultSortFieldId={props.defaultSortFieldId || ''}
				defaultSortAsc={props.defaultSortAsc || true}
			/>
		</div>
	);
};
export default React.memo(Table);

export function NODATAFOUND(page?: any, scrollHeight?: any) {
	const theme: any = useTheme();
	return (
		<Grid
			padding={'24px'}
			width='100%'
			height={scrollHeight}
			bgcolor={theme.palette.mode == 'dark'? "black" : "white" }
			color={theme.palette.text.primary}
			display='flex'
			style={page ? { boxShadow: '0px 5px 12px #0000001a' } : {}}
			justifyContent={'center'}
			className='no_records_to_display'
		>
			There are no records to display
		</Grid>
	);
}
