import { Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/styles';
import './../../PredictiveScoring.scss';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ToolTipOnText from '../../../../components/Tooltip/Tooltip';

const Confusion: React.FC<any> = (props: any) => {
	const theme: any = useTheme();

	return (
		<Grid
			xs={4}
			style={{
				display: 'flex',
				flexDirection: 'row',
				gap: '20px',
				width: '100%',
				maxWidth: '80%',
				padding: "20px"
			}}
		>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					flexWrap: 'wrap',
					alignContent: 'center',
				}}
			>
				<Typography variant='h5'>Actual</Typography>
			</div>
			<div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						marginBottom: '20px',
					}}
				>
					<Typography variant='h5'>Predicted</Typography>
				</div>
				<Grid container justifyContent='center' mb={5}>
					{props.data.map((item: any, index: number) => (
						<Grid item xs={6} key={index} mb={1}>
							<Grid
								className='confusionMatrix_tab'
								mr={1}
								style={{
									backgroundColor: item.color,
									flexDirection: 'column',
								}}
							>
								<Typography className='confusionMatrix_tab_text'>
									{item.label}
								</Typography>
								<Typography variant='h3' className='confusionMatrix_tab_text'>
									{item.value}
								</Typography>
							</Grid>
						</Grid>
					))}
				</Grid>
			</div>
		</Grid>
	);
};

export default React.memo(Confusion);
