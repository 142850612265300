import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTheme } from '@mui/styles';
import React, { forwardRef, useImperativeHandle, useState } from 'react';

import Button from '../Button/Button';
import Draggable from 'react-draggable';
import Paper, { PaperProps } from '@mui/material/Paper';
import './Dialog.scss';

function PaperComponent(props: PaperProps) {
	return (
		<Draggable
			handle='#draggable-dialog-title'
			cancel={'[class*="MuiDialogContent-root"]'}
		>
			<Paper {...props} />
		</Draggable>
	);
}
interface IDialogLoaderProps {
	ref: any;
	onDiscard: any;
	onConfirm: any;
	customHTMLContent?: any;
	deleteAccountTitle?: any;
	color?: any;
	description?: any;
	onBackClick?: any;
	// Variables
	title?: any;
	hideCloseButton?: boolean;
	confirm?: string;
	disableConfirmButton?: boolean;
	discard?: string;
	subHeadingTitle?: string;
	showBackButton?: boolean;
	// Classes
	dialogRootClass?: any;
	dialogTitleRootClass?: any;
	page?: any;
	maxWidth?: any;
	sx?: any;
}

const AlertDialog: React.FC<IDialogLoaderProps> = forwardRef(
	(props: any, ref: any) => {
		const theme: any = useTheme();
		const [open, setOpen] = useState(false);

		// Call these functions using ref from the component.
		useImperativeHandle(ref, () => ({
			handleClickOpen: () => {
				setOpen(true);
			},
			handleClose: () => {
				setOpen(false);
			},
		}));

		return (
			<div className='Dialog'>
				<Dialog
					open={open}
					PaperComponent={PaperComponent}
					onClose={props.onDiscard}
					aria-labelledby='draggable-dialog-title'
					aria-describedby='alert-dialog-description'
					maxWidth={props.maxWidth}
					sx={props.sx}
					classes={{
						paper: `${
							props.dialogRootClass ? props.dialogRootClass : 'Dialog__root'
						}`,
					}}
				>
					<DialogTitle
						style={props.page ? { cursor: 'move', fontSize: '22px' } : {}}
						id='draggable-dialog-title'
						classes={{
							root: `${
								!props.page
									? props.dialogTitleRootClass
										? props.dialogTitleRootClass
										: 'Dialog__titleRoot'
									: 'Dialog__titleRoot2'
							}`,
						}}
					>
						{props.title}
						<br />
						{props.subHeadingTitle ? props.subHeadingTitle : ''}
						{props.deleteAccountTitle ? props.deleteAccountTitle : ''}

						{props.hideCloseButton ? (
							''
						) : (
							<IconButton
								className='Dialog__closeBtn'
								onClick={() => {
									setOpen(false);
									if (props.onDiscard) {
										props.onDiscard();
									}
								}}
							>
								<CancelIcon htmlColor={theme.palette.text.primary} />
							</IconButton>
						)}
					</DialogTitle>
					<DialogContent
						classes={{
							root: props.page
								? 'Dialog__dialogContentRoot2'
								: 'Dialog__dialogContentRoot',
						}}
					>
						{props.customHTMLContent ? props.customHTMLContent() : null}

						{props.description ? (
							<DialogContentText
								id='alert-dialog-description'
								style={{
									textAlign: 'center',
									fontSize: '1.1rem',
									color: theme.palette.text.primary,
								}}
							>
								{props.description}
							</DialogContentText>
						) : (
							''
						)}
					</DialogContent>

					<DialogActions
						style={!props.confirm ? { padding: '0rem', height: '0px' } : {}}
					>
						<div className='Dialog__buttonGroup'>
							{props.showBackButton ? (
								<div
									className='Dialog__backButton'
									onClick={() => props.onBackClick()}
								>
									{/* <BackActionButton /> */}
									<KeyboardBackspaceIcon color='primary' />
								</div>
							) : null}
							{props.confirm ? (
								<Button
									type='button'
									disable={!!props.disableConfirmButton}
									color={props.color ? props.color : 'primary'}
									btnText={props.confirm}
									onClick={() => {
										props.onConfirm();
									}}
								/>
							) : null}

							{props.discard ? (
								<Button
									type='button'
									disable={props.isButtonDisabled}
									btnText={props.discard}
									onClick={props.onDiscard}
									color='secondary'
									variant='outlined'
								/>
							) : null}
						</div>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
);

export default AlertDialog;
