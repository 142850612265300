import React, { lazy, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PredictiveScoring from './containers/AdvertisementChannel/PredictiveScoring';

// Function to resolve loading chunk issue

const lazyWithRetry = (componentImport: any, name: any) => async () => {
	const pageHasAlreadyBeenForceRefreshed = JSON.parse(
		window.sessionStorage.getItem(`retry-${name}-refreshed`) || 'false'
	);

	try {
		const component = await componentImport();

		window.sessionStorage.setItem(`retry-${name}-refreshed`, 'false');

		return component;
	} catch (error) {
		if (!pageHasAlreadyBeenForceRefreshed) {
			// Assuming that the user is not on the latest version of the application.
			// Let's refresh the page immediately.
			window.sessionStorage.setItem(`retry-${name}-refreshed`, 'true');
			return window.location.reload();
		}

		// The page has already been reloaded
		// Assuming that user is already using the latest version of the application.
		// Let's let the application crash and raise the error.
		throw error;
	}
};

const AIChatBot = lazy(
	lazyWithRetry(() => import('./containers/AIChatBot/AIChatBot'), 'aiChatBot')
);

const Login = lazy(
	lazyWithRetry(() => import('./containers/Login/Login'), 'login')
);

const InvitedUser = lazy(
	lazyWithRetry(() => import('./containers/Login/InvitedUser'), 'invitedUser')
);
const MMM = lazy(
	lazyWithRetry(
		() => import('./containers/AdvertisementChannelold/AdvertisementChannel'),
		'MMM'
	)
);

const MMMDevelop = lazy(
	lazyWithRetry(
		() => import('./containers/MMM/MMM'),
		'MMM'
	)
);

const DataEnrichment = lazy(
	lazyWithRetry(
		() => import('./containers/DataEnrichment/DataEnrichment'),
		'DataEnrichment'
	)
);

const EditProfile = lazy(
	lazyWithRetry(
		() => import('./containers/EditProfile/EditProfile'),
		'editprofile'
	)
);


const AppRoutes: React.FC = () => (
	<Suspense fallback=''>
		<Routes>
			<Route path='invited/login' element={<InvitedUser />} />
			<Route path='/login' element={<Login />} />
			<Route path='/AIEditProfile' element={<EditProfile />} />
			<Route path='/editProfile' element={<EditProfile />} />
			<Route path='' element={<Navigate to='/login' />} />
			<Route path='/insights/predictive-scoring' element={<PredictiveScoring />} />
			<Route path='/insights/MMM' element={<MMM/>} />
			<Route path='/insights/Media-Mix-Modelling' element={<MMMDevelop/>} />
			<Route path='/insights/Data-Enrichment' element={<DataEnrichment/>} />
			<Route path='/insights/chatbot' element={<AIChatBot />} />
		</Routes>
	</Suspense>
);

export default AppRoutes;
